import { createAction, props } from '@ngrx/store';
import { PGConnectionMetadata } from 'src/app/core/models/API/pg-connection/pg-connection-metadata.model';

export enum PGConnectionActionTypes {
  REQUEST_PG_METADATA = '[PG Connection] Request PG Metadata',
  SUCCESS_PG_METADATA = '[PG Connection] Request PG Metadata Success',
  FAILURE_PG_METADATA = '[PG Connection] Request PG Metadata Failure',
}

export const getPGMetadata = createAction(
  PGConnectionActionTypes.REQUEST_PG_METADATA
);

export const getPGMetadataSuccess = createAction(
  PGConnectionActionTypes.SUCCESS_PG_METADATA,
  props<{ payload: PGConnectionMetadata }>()
);

export const getPGMetadataFailure = createAction(
  PGConnectionActionTypes.FAILURE_PG_METADATA
);
