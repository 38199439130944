import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'gap-empty',
  templateUrl: './empty.component.html'
})
export class EmptyComponent implements OnInit {
  @Input() emptyMessage ? = 'you.';
  imagePath = environment.imagePath;

  // emptyMessage = `We couldn't find any results`;

  constructor() { }

  ngOnInit(): void {
  }

}
