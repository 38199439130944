import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { NotificationComponent } from 'src/app/shared/components/notification/notification.component';
import { SnackBarConfig } from '../../models/UI/snack-bar/snack-bar.model';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService implements OnDestroy {
  private notificationSub: Subscription;

  constructor(private snackBar: MatSnackBar) {}

  show(data: SnackBarConfig): void {
    const config = new MatSnackBarConfig();
    config.verticalPosition = data.verticalPosition
      ? data.verticalPosition
      : 'bottom';

    config.horizontalPosition = data.horizontalPosition
      ? data.horizontalPosition
      : 'center';

    config.duration = data.auto_dismiss === false ? 0 : 600000;

    // const snackRef = this.snackBar.open(data.message, data.action, config);
    const snackRef = this.snackBar.openFromComponent(NotificationComponent, {
      duration: config.duration,
      panelClass: 'gap-notification',
      data: { message: data.message, action: data.action, config }
    });

    this.notificationSub = snackRef.onAction().subscribe(() => {
      this.notificationSub.unsubscribe();
      if (data.action === 'REFRESH') {
          snackRef.dismiss();
          window.location.reload();
      }
      else {
          snackRef.dismiss();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.notificationSub) {
      this.notificationSub.unsubscribe();
    }
  }
}
