import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import {
  GetNewTokenByRefreshTokenRequest,
  LoginRequest,
  ResendOtpRequest,
  Verify2FAOTPRequest,
} from 'src/app/modules/login/login/dtos/login-request';
import { LoginOtpResponse, LoginResponse, TokenVerifyResponse } from 'src/app/modules/login/login/dtos/login-response';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  userStatusesReqURL = 'adm-portal/user/statuses';
  personalIDTypesReqURL = 'adm-portal/personalid/types';
  merchantListReqURL = 'adm-portal/merchants/list?name=';
  outletsListReqURL = 'adm-portal/outlets?name=';
  createUserReqURL = 'adm-portal/create/user';
  editUserReqURL = 'adm-portal/edit/user';
  tokenVerifyUrl ='adm-enterprise/internal/token/verify';

  constructor(private http: HttpClient) { }

  /**
   * user types API call for roles
   * @returns an array of user types
   */
  getUserTypes(): Observable<any> {
    const url = 'roles/usertypes';
    return this.http.get<any>(url).pipe(
      map((response) => response.userTypeDtoList)
    );
  }

  /**
   * user types API call for users
   * @returns an array of user types
   */
  getUserTypesForUsers(): Observable<any> {
    const url = 'adm-portal/user/types';
    return this.http.get<any>(url);
  }

  /**
   * user type API call
   * @returns an array of user roles
   */
  getUserRoles(): Observable<any> {
    const url = 'roles/viewall/roles';
    return this.http.get<any>(url).pipe(
      map((response) => response.portalRoles)
    );
  }

  /**
   * user type API call
   * @returns an array of user roles
   */
  getRolesForUserType(userTypeID: number): Observable<any> {
    const url = 'roles/view/usertype/' + userTypeID;
    return this.http.get<any>(url).pipe(
      map((response) => response)
    );
  }

  /**
   * get all users
   * @returns an array of user objects
   */
  getAllUsers(pageStart: number, pageEnd: number): Observable<any> {
    const url = 'adm-portal/view/user?id=&pageStart=' + pageStart + '&pageEnd=' + pageEnd;
    return this.http.get<any>(url).pipe(
      map((response) => response)
    );
  }

  /**
   * search users
   * @returns an array of user objects which matches to the passed string
   */
  searchUsers(searchTxt: string, pageStart: number, pageEnd: number, status = '', type = ''): Observable<any> {
    const url = 'adm-portal/search/user?name=' + searchTxt + '&status='
      + status + '&type=' + type + '&pageStart=' + pageStart + '&pageEnd=' + pageEnd;
    return this.http.get<any>(url).pipe(
      map((response) => response)
    );
  }

  /**
   * search users
   * @param userId ID of the user which needs to be deleted
   * @returns delete confirmation
   */
  deleteUsers(userId: number): Observable<any> {
    const url = 'adm-portal/delete/user/' + userId;
    return this.http.post<any>(url, {}).pipe(
      map((response) => response)
    );
  }

  /**
   * get user statuses
   * @returns delete confirmation
   */
  getUserStatuses(): Observable<any> {
    return this.http.get<any>(this.userStatusesReqURL).pipe(
      map((response) => response)
    );
  }

  /**
   * get personalIDs
   * @param userId ID of the user which needs to be deleted
   * @returns delete confirmation
   */
  getPersonalIDs(): Observable<any> {
    return this.http.get<any>(this.personalIDTypesReqURL).pipe(
      map((response) => response)
    );
  }

  /**
   * get merchants
   * @param userId ID of the user which needs to be deleted
   * @returns delete confirmation
   */
  getMerchants(name: string, pageStart = 0, pageEnd = 0): Observable<any> {
    const url = this.merchantListReqURL + name + '&pageStart=' + pageStart + '&pageEnd=' + pageEnd;
    return this.http.get<any>(url).pipe(
      map((response) => response)
    );
  }

  /**
   * get outlets
   * @param userId ID of the user which needs to be deleted
   * @returns delete confirmation
   */
  getOutlets(name: string, pageStart = 0, pageEnd = 0): Observable<any> {
    const url = this.outletsListReqURL + name + '&pageStart=' + pageStart + '&pageEnd=' + pageEnd;
    return this.http.get<any>(url).pipe(
      map((response) => response)
    );
  }

  /**
   * get outlet by ID
   * @param userId ID of the user which needs to be deleted
   * @returns delete confirmation
   */
  getOutletsByID(outletID: number): Observable<any> {
    const url = 'adm/outlet/merchant/' + outletID;
    return this.http.get<any>(url).pipe(
      map((response) => response)
    );
  }

  /**
   * create user
   * @param userId ID of the user which needs to be deleted
   * @returns delete confirmation
   */
  addUsers(userObject: any): Observable<any> {
    return this.http.post<any>(this.createUserReqURL, userObject).pipe(
      map((response) => response)
    );
  }

  /**
   * edit user
   * @param userId ID of the user which needs to be deleted
   * @returns delete confirmation
   */
   editUsers(userObject: any): Observable<any> {
    return this.http.post<any>(this.editUserReqURL, userObject).pipe(
      map((response) => response)
    );
  }

  /**
   * get user details by ID
   * @param userId ID of the user which needs to be deleted
   * @returns delete confirmation
   */
   getUserDetailsByID(userID: number): Observable<any> {
    const url = 'adm-portal/view/user/' + userID;
    return this.http.get<any>(url).pipe(
      map((response) => response)
    );
  }


  tokenVerify(payload: any): Observable<TokenVerifyResponse>{
    return this.http.post<TokenVerifyResponse>(this.tokenVerifyUrl, payload)
  }
}
