import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DNSConfigs } from '../configs/DNS.config';
import { AuthService } from '../services/authentication/auth.service';
import { LoaderService } from '../services/loader/loader.service';
import { SnackBarService } from '../services/snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private auth: AuthService,
    private loaderService: LoaderService,
    private router: Router,
    private authService: AuthService,
    private snackbarService: SnackBarService,
    private dialogRef: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.showLoader(request.url);
    request = this.appendTokenAndBaseUrl(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.statusText === 'Unauthorized' ||
          error.status === 401 ||
          error.error.error === 'invalid_token'
        ) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      }),
      finalize(() => {
        this.loaderService.isLoading.next(false);
        this.loaderService.isProgressBarLoading.next(false);
      })
    ) as Observable<HttpEvent<any>>;
  }

  /**
   * @param request Http request
   * @description Append token and base url to the request
   */
  private appendTokenAndBaseUrl(request: HttpRequest<any>): HttpRequest<any> {
    const DNS_URL = window.location.protocol + '//' + window.location.host;
    let baseUrl = environment.privateBaseUrl;
    let loginUrl = '';
    let logoutUrl = '';
    let requestUrl = '';
    let otpVerifyURL = '';
    let tokenVerifyUrl ='';

    // check with DNS and loginType
    if ( DNS_URL === DNSConfigs.private || localStorage.getItem('loginType') === 'internal' ) {
      baseUrl = environment.privateBaseUrl;
      loginUrl = 'adm-enterprise/internal/login/v2';
      otpVerifyURL = 'adm-enterprise/internal/otp/verify';
      tokenVerifyUrl ='adm-enterprise/internal/token/verify'
      logoutUrl = 'adm-enterprise/internal/logout';
    } else if ( DNS_URL === DNSConfigs.public || localStorage.getItem('loginType') === 'external' ) {
      baseUrl = environment.publicBaseUrl;
      loginUrl = 'adm-enterprise/external/login/v2';
      otpVerifyURL = 'adm-enterprise/external/otp/verify';
      tokenVerifyUrl ='adm-enterprise/external/token/verify'
      logoutUrl = 'adm-enterprise/external/logout';
    }

    // append login/logout url
    if (request.url.includes('/login')) {
      requestUrl = baseUrl + loginUrl;
    } else if (request.url.includes('/logout')) {
      requestUrl = baseUrl + logoutUrl;
    } else if (request.url.includes('/otp')) {
      requestUrl = baseUrl + otpVerifyURL;
    }else if (request.url.includes('/token/verify')){
      requestUrl = baseUrl + tokenVerifyUrl;

    }
    // for the requests after refreshtoken request
    else if (request.url.includes(baseUrl)) {
      requestUrl = `${request.url}`;
    } else {
      requestUrl = baseUrl + `${request.url}`;
    }

    // appending bearer token
    const token = this.auth.getToken();
    if (
      (!request.url.includes('/login') ||
        !request.url.includes('/refresh-access')||
        !request.url.includes('/otp')) &&
      token &&
      token !== 'null'
    ) {
      return request.clone({
        url: `${requestUrl}`,
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return request.clone({
        url: `${requestUrl}`,
      });
    }
  }

  /**
   * @param request Request
   * @param next Next
   * @description Handle 401 error and get new accessToken from refershToken
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): any {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      this.authService.removeToken();
      const refreshToken = this.authService.getRefreshToken();
      const requestPayload = {
        refreshToken,
      };
      return this.authService
        .getNewAccessTokenFromRefreshToken(requestPayload)
        .pipe(
          map((res: any) => res),
          switchMap((res: any) => {
            if (res.code === '0000') {
              this.isRefreshing = false;
              localStorage.setItem('access_token', res.accessToken);
              localStorage.setItem('refresh_token', res.refreshToken);
              this.refreshTokenSubject.next(res.refreshToken);
              return next.handle(this.appendTokenAndBaseUrl(request));
            } else {
              return this.logout('An error occurred');
            }
          }),

          finalize(() => (this.isRefreshing = false))
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => {
          return next.handle(this.appendTokenAndBaseUrl(request));
        })
      );
    }
  }

  /**
   * logout user if refresh access fails
   */
  logout(message: string): any {
    this.snackbarService.show({
      message: 'Session timeout. Please login again',
      action: 'error',
    });
    localStorage.clear();
    this.dialogRef.closeAll();
    this.router.navigate(['/login']);
    // remove loader after logout/session out
    this.loaderService.isLoading.next(false);
    this.loaderService.isProgressBarLoading.next(false);
    this.isRefreshing = false;
    return of('');
  }

  /**
   * enabling loader and progress bar for certain request
   * for APIs which loads data to dropdowns and expanded view, need to disable the main loader
   * @param REQUEST_URL API request URL
   */
  showLoader(REQUEST_URL: string): void {
    // URL lists which do not need main loader
    const URL_LIST = [
      'roles/role/details',
      'adm-portal/merchants',
      'report/merchant/business-name',
      'adm-portal/billertype',
      'axipay/report/enterprise-ttr/export/feasibility',
      'axipay/report/merchant-ttr/export/feasibility',
      'axipay/report/enterprise-ttr/export/push',
      'axipay/report/merchant-ttr/export/push',
      'axipay/report/report/export/link/merchant?reportId=',
      'axipay/report/report/export/link/enterprise?reportId=',
      'axipay/report/report/export/link/recurrent?reportId=',
      'adm-portal/recurring/sto/payment/plan/report/download'
    ];
    // URL lists need progress bar
    const URL_LIST_PB = ['roles/role/details'];

    const IS_URL_MATCHED = URL_LIST.some((URL) => {
      return REQUEST_URL.indexOf(URL) !== -1;
    });
    const IS_PB_URL_MATCHED = URL_LIST_PB.some((URL) => {
      return REQUEST_URL.indexOf(URL) !== -1;
    });

    if (IS_URL_MATCHED !== true) {
      this.loaderService.isLoading.next(true);
    } else if (IS_PB_URL_MATCHED === true) {
      this.loaderService.isProgressBarLoading.next(true);
    }
  }
}
