import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PERMISSIONS } from 'src/app/core/configs/permission';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import {ConfirmRemoveComponent} from '../../common-popups/confirm-remove/confirm-remove.component';

@Component({
  selector: 'gap-expanded-header',
  templateUrl: './expanded-header.component.html'
})
export class ExpandedHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() resultIcon ? = 'point_of_sale';
  @Input() itemID;
  @Input() componentTitle;
  @Input() showCancelOption = false;
  @Input() editPermission?: string[];
  @Input() deletePermission?: string[];
  // When product is updated
  @Output() emitEditHandler = new EventEmitter<number>();

  loading = true;
  loaderSub: Subscription;

  @Output() emitDeleteHandler = new EventEmitter<number>();
  @Output() emitCancelPaymentPlan = new EventEmitter<any>();

  cancelPermission = PERMISSIONS.cancelRecurrentPlanPermission;

  constructor(
    public dialog: MatDialog,
    private loaderService: LoaderService) {
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.loaderSub = this.loaderService.isProgressBarLoading.subscribe((v) => {
      if (v === false) {
        setTimeout(() => {
          this.loading = v;
        }, 500);
      }
    });
  }

  /**
   * Edits handler
   * @param itemID ID of the item which needs to be editted
   */
  editHandler(itemID: number): void {
    this.emitEditHandler.emit(itemID);
  }

  /**
   * Deletes handler
   * @param itemID Id of the item which needs to be deleted
   */
  deleteHandler(itemID: number): void {
    const dialogRef = this.dialog.open(ConfirmRemoveComponent, {
      width: '600px',
      maxWidth: '100% ',
      maxHeight: '100%',
      panelClass: 'myStyle',
    });
    dialogRef.componentInstance.title = 'Remove' + '  ' + this.componentTitle;
    dialogRef.componentInstance.message =
      'Do you really want to delete? Please confirm this operation';
    dialogRef.componentInstance.component = this.componentTitle;
    dialogRef.componentInstance.yesButtonFunctionWithParam = this.deleteHandlerEmit;
    dialogRef.componentInstance.subscribeObject = itemID;

    dialogRef.beforeClosed().subscribe(
      (response) => {
        if (response === true){
          this.deleteHandlerEmit(itemID);
        }
      }
    );
  }

  /**
   * Deletes handler emit
   * @param itemID Item ID
   */
  deleteHandlerEmit(itemID: number): void {
    this.emitDeleteHandler.emit(itemID);
  }

  /**
   * cancel payment plan
   * @param itemID Item ID
   */
  cancelPaymentPlan(recurringID: number, accountID: any): void {
    this.emitCancelPaymentPlan.emit({recurringID, accountID });
  }

  ngOnDestroy(): void {
    if (this.loaderSub) {
      this.loaderSub.unsubscribe();
    }
  }
}
