import {Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AnimationOptions } from 'ngx-lottie';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'gap-notification',
  templateUrl: './notification.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {

  @Output() closeNotification = new EventEmitter<any>();

  imagePath = environment.imagePath;
  imageType = '';
  options: AnimationOptions;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private notificationRef: MatSnackBarRef<NotificationComponent>) { }

  ngOnInit(): void {
    if (this.data.action === 'error') {
      this.imageType = 'error.json';
    }
    else if (this.data.action === 'success') {
      this.imageType = 'success.json';
    }
    else if (this.data.action === 'delete') {
      this.imageType = 'delete.json';
    }
    this.options = {
      path: this.imagePath + this.imageType,
      autoplay: true,
      loop: true
    };
  }

  /**
   * close notification
   */
  closePanel(): void {
    this.notificationRef.dismiss();
  }
}
