import {Component, OnDestroy, OnInit} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SidenavSelector } from 'src/app/store/selectors/SidenavSelector';

@Component({
  selector: 'gap-item-layout',
  templateUrl: './item-layout.component.html'
})
export class ItemLayoutComponent implements OnInit, OnDestroy {

  subMenuState = false;
  HideMenu = false;

  storeSubscribe: Subscription;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.sidenavListener();
  }

  // show and hide the side nav
  toggleMenu(event): void {
    this.subMenuState = event;
  }

  // hide the icon when toggle or mousehover / mouseleave
  HideSideMenuOnClick(event): void {
    this.HideMenu = event;
  }

  /**
   * listen to initial state of sidenav
   */
  sidenavListener(): void{
    this.storeSubscribe = this.store.pipe(
        select(SidenavSelector.selectStatusState)
    )
    .subscribe((state) => {
      this.subMenuState = state;
      this.HideMenu = state;
    });
  }

  ngOnDestroy(): void {
    this.storeSubscribe.unsubscribe();
  }
}
