import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gap-image-crop',
  templateUrl: './image-crop.component.html'
})
export class ImageCropComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
