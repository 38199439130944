import {AddPaymentTerms} from '../../models/UI/merchant/addMerchantPaymentTerms';

export const paymentTermItem: AddPaymentTerms[] = [
  {
    paymentOption: 'eZ Cash',
    preferredPaymentIpg: 'xxx',
    settlementType: 'eZ Settlement',
    eZCashMerchantWalletAlias: 'xxx',
    setupFee: '100',
    settPeriod: 'Weekly',
    acquirerMid: '1010101',
    clientIdMoto: '1010101',
    clientIdGuest: '1010',
    action: 'Delete',
  },
  {
    paymentOption: 'eZ Cash',
    preferredPaymentIpg: 'xxx',
    settlementType: 'eZ Settlement',
    eZCashMerchantWalletAlias: 'xxx',
    setupFee: '100',
    settPeriod: 'Weekly',
    acquirerMid: '1010101',
    clientIdMoto: '1010101',
    clientIdGuest: '1010',
    action: 'Delete',
  },

];
type tableHeader = Array<{key: string, header: string}>;

export const payTermTableDisplay:tableHeader = [
  // {
  //   key: 'paymentOption',
  //   header: 'Payment Option',
  //   className: 'something',
  // },
  // {
  //   key: 'preferredPaymentIpg',
  //   header: 'Preferred Payment IPG',
  //   className: 'something',
  // },
  // {
  //   key: 'settlementType',
  //   header: 'Settlement Type',
  //   className: 'something',
  // },
  // {
  //   key: 'walletAllias',
  //   header: 'EzCash Merchant Wallet Alias',
  //   className: 'something',
  // },
  // {
  //   key: 'setupFee',
  //   header: 'Setup Fee',
  //   className: 'something',
  // },
  // {
  //   key: 'settlementPeriod',
  //   header: 'Settlment Period',
  //   className: 'something',
  // },
  // {
  //   key: 'acquirerMid',
  //   header: 'Acqire MID',
  //   className: 'something',
  // },
  // {
  //   key: 'clientIdMoto',
  //   header: 'Client ID Moto',
  //   className: 'something',
  // },
  // {
  //   key: 'clientIdGuest',
  //   header: 'Client ID Guest',
  //   className: 'something',
  // },
  // {
  //   key: 'action',
  //   header: 'Action',
  //   className: 'something',
  // }
  {
    key: 'paymentOptionTypeId',
    header: 'Payment option',
  },
  {
    key: 'merchantPreferedIpgId',
    header: 'Preferred Payment IPG',
  },
  {
    key: 'settlementTypeId',
    header: 'Settlement Type',
  },
  {
    key: 'walletAllias',
    header: 'eZCash Merchant Wallet Alias',
  },
  {
    key: 'setupFee',
    header: 'Setup Fee',
  },
  {
    key: 'settlementPeriod',
    header: 'Sett. Period',
  },
  {
    key: 'acquirerMid',
    header: 'Acquirer MID',
  },
  {
    key: 'clientIdMoto',
    header: 'Client ID - MOTO',
  },
  {
    key: 'clientIdGuest',
    header: 'Client ID - Guest',
  },
  {
    key: 'compact',
    header: 'Compact MIDs',
  },
];

type tableHeaderArray = Array<{key: string, header: string, className:string}>;

export const payTermTableDisplayData:tableHeaderArray = [
  {
    key: 'id',
    header: 'Payment Option',
    className: 'something',
  },
  // {
  //   key: 'merchantPreferedIpgId',
  //   header: 'Preferred Payment IPG',
  //   className: 'something',
  // },
  {
    key: 'settlementType',
    header: 'Settlement Type',
    className: 'something',
  },
  {
    key: 'walletAllias',
    header: 'EzCash Merchant Wallet Alias',
    className: 'something',
  },
  {
    key: 'setupFee',
    header: 'Setup Fee',
    className: 'something',
  },
  {
    key: 'settlementPeriod',
    header: 'Settlment Period',
    className: 'something',
  },
  {
    key: 'acquirerMid',
    header: 'Acqire MID',
    className: 'something',
  },
  {
    key: 'clientIdMoto',
    header: 'Client ID Moto',
    className: 'something',
  },
  {
    key: 'clientIdGuest',
    header: 'Client ID Guest',
    className: 'something',
  },
  {
    key: 'bankGuaranteeValue',
    header: 'Bank Gurantee Value',
    className: 'something',
  },
  {
    key: 'action',
    header: 'Action',
    className: 'something',
  }
];

