export const PERMISSIONS = {
  // dashboard
  dashboardPermission: ['View_dashboards/package'],
  // merchants-sidenav
  merchantSideNavPermission: ['View_merchants/package'],
  merchantMyMerchantSubSideNavPermission: [
    'View_merchants/my merchants/module',
  ],
  merchantPgConnectionSubSideNavPermission: [
    'View_merchants/pg connections/module',
  ],
  merchantPayeeTypesSubSideNavPermission: ['View_merchants/payee types/module'],
  merchantQRMapperSubSideNavPermission: ['View_merchants/qr mapper/module'],
  merchantDicountsSubSideNavPermission: [
    'View_merchants/merchant discounts/module',
  ],
  // merchant-add
  addDataMerchantsPgConnectionsPermission: [
    'Add_merchants/pg connections/data/entity',
  ],
  addDataMerchantsMyMerchantsPermission: [
    'Add_merchants/my merchants/data/entity',
  ],
  addDataMerchantsPayeeTypesPermission: [
    'Add_merchants/payee types/data/entity',
  ],
  addDataMerchantsQRMapperPermission: ['Add_merchants/qr mapper/data/entity'],
  addDataMerchantsDiscountsPermission: ['Add_merchants/merchant discounts/data/entity'],
  // merchant-view
  viewDataMerchantsPgConnectionsPermission: [
    'View_merchants/pg connections/data/entity',
  ],
  viewDataMerchantsMyMerchantsPermission: [
    'View_merchants/my merchants/data/entity',
  ],
  viewDataMerchantsPayeeTypesPermission: [
    'View_merchants/payee types/data/entity',
  ],
  viewDataMerchantsQRMapperPermission: ['View_merchants/qr mapper/data/entity'],
  viewDataMerchantsDiscountsPermission: [
    'View_merchants/merchant discounts/data/entity',
  ],
  // merchant-delete
  deleteDataMerchantsPgConnectionsPermission: [
    'Delete_merchants/pg connections/data/entity',
  ],
  deleteDataMerchantsPayeeTypesPermission: [
    'Delete_merchants/payee types/data/entity',
  ],
  deleteDataMerchantsMyMerchantsPermission: [
    'Delete_merchants/my merchants/data/entity',
  ],
  deleteDataMerchantsQRMapperPermission: [
    'Delete_merchants/qr mapper/data/entity',
  ],
  deleteDataMerchantsDiscountsPermission: [
    'Delete_merchants/merchant discounts/data/entity',
  ],
  // merchant-edit
  editDataMerchantsPgConnectionsPermission: [
    'Edit_merchants/pg connections/data/entity',
  ],
  editDataMerchantsPayeeTypesPermission: [
    'Edit_merchants/payee types/data/entity',
  ],
  editDataMerchantsMyMerchantsPermission: [
    'Edit_merchants/my merchants/data/entity',
  ],
  editDataMerchantsQRMapperPermission: ['Edit_merchants/qr mapper/data/entity'],
  editDataMerchantsDiscountsPermission: [
    'Edit_merchants/merchant discounts/data/entity',
  ],
  // bulk merchant activation/deactivation
  bulkMerchantActivationDeactivationPackagePermission: ['View_merchants/activate/deactivate/package'],
  bulkMerchantActivationModulePermission: ['View_merchants/activate/module'],
  bulkMerchantDeactivationModulePermission: ['View_merchants/deactivate/module'],
  bulkMerchantActivationEntityPermission: ['Edit_merchants/activate/entity'],
  bulkMerchantDeactivationEntityPermission: ['Edit_merchants/deactivate/entity'],

  // outlets
  outletSideNavPermission: ['View_outlets/package'],
  outletsMyOutletsSubSideNavPermission: ['View_outlets/my outlets/module'],
  outletsCountersSubSideNavPermission: ['View_outlets/counters/module'],

  viewDataMyOutletsPermission: ['View_outlets/my outlets/data/entity'],
  deleteDataMyOutletsPermission: ['Delete_outlets/my outlets/data/entity'],
  addDataMyOutletsPermission: ['Add_outlets/my outlets/data/entity'],
  editDataMyOutletsPermission: ['Edit_outlets/my outlets/data/entity'],

  editDataCountersPermission: ['Edit_outlets/counters/data/entity'],
  ViewDataCountersPermission: ['View_outlets/counters/data/entity'],
  deleteDataCountersPermission: ['Delete_outlets/counters/data/entity'],
  addDataCountersPermission: ['Add_outlets/counters/data/entity'],

  // users
  usersSideNavPermission: ['View_users/package'],
  usersMyUsersSubSideNavPermission: ['View_users/my users/module'],
  usersRolesAndPrivilegesSubSideNavPermission: [
    'View_users/roles and privileges/module',
  ],
  // users-my users
  viewDataUsersMyUsersPermission: ['View_users/my users/data/entity'],
  addDataUsersMyUsersPermission: ['Add_users/my users/data/entity'],
  editDataUsersMyUsersPermission: ['Edit_users/my users/data/entity'],
  deleteDataUsersMyUsersPermission: ['Delete_users/my users/data/entity'],
  // users-roles&privilages
  viewDataUsersRolesAndPrivilegesPermission: [
    'View_users/roles and privileges/data/entity',
  ],
  addDataUsersRolesAndPrivilegesPermission: [
    'Add_users/roles and privileges/data/entity',
  ],
  editDataUsersRolesAndPrivilegesPermission: [
    'Edit_users/roles and privileges/data/entity',
  ],
  deleteDataUsersRolesAndPrivilegesPermission: [
    'Delete_users/roles and privileges/data/entity',
  ],
  // users-user groups
  viewDataUsersUserGroupsPermission: ['View_users/user groups/data/entity'],
  addDataUsersUserGroupsPermission: ['Add_users/user groups/data/entity'],
  editDataUsersUserGroupsPermission: ['Edit_users/user groups/data/entity'],
  deleteDataUsersUserGroupsPermission: ['Delete_users/user groups/data/entity'],

  // customers
  customersSideNavPermission: ['View_dashboards/customers/module'],

  // settlements
  settlementsSideNavPermission: ['View_settlements/package'],
  settlementsBatchesSubSideNavPermission: [
    'View_settlements/settlement batches/module',
  ],
  settlementsInternalReconciliations: [
    'View_settlements/internal reconciliations/module',
  ],
  settlementsExternalReconciliations: [
    'View_settlements/external reconciliations/module',
  ],
  // settlements-settlement batches
  editDataSettlementsSettlementBatchesPermission: [
    'Edit_settlements/settlement batches/data/entity',
  ],
  viewDataSettlementsSettlementBatchesPermission: [
    'View_settlements/settlement batches/data/entity',
  ],
  deleteDataSettlementsSettlementBatchesPermission: [
    'Delete_settlements/settlement batches/data/entity',
  ],
  addDataSettlementsSettlementBatchesPermission: [
    'Add_settlements/settlement batches/data/entity',
  ],
  // settlements-internal reconciliation
  editDataSettlementsInternalReconciliationPermission: [
    'Edit_settlements/internal reconciliations/data/entity',
  ],
  viewDataSettlementsInternalReconciliationPermission: [
    'View_settlements/internal reconciliations/data/entity',
  ],
  deleteDataSettlementsInternalReconciliationPermission: [
    'Delete_settlements/internal reconciliations/data/entity',
  ],
  addDataSettlementsInternalReconciliationPermission: [
    'Add_settlements/internal reconciliations/data/entity',
  ],
  // settlements-external reconciliation
  editDataSettlementsExternalReconciliationPermission: [
    'Edit_settlements/external reconciliations/data/entity',
  ],
  viewDataSettlementsExternalReconciliationPermission: [
    'View_settlements/external reconciliations/data/entity',
  ],
  deleteDataSettlementsExternalReconciliationPermission: [
    'Delete_settlements/external reconciliations/data/entity',
  ],
  addDataSettlementsExternalReconciliationPermission: [
    'Add_settlements/external reconciliations/data/entity',
  ],

  // deals
  dealsSideNavPermission: [''],

  // notifications
  notificationSideNavPermission: [''],
  notificationPushNotificationSubSideNavPermission: [''],

  viewDataNotificationPushNotificationPermission: [''],
  editDataNotificationPushNotificationPermission: [''],
  addDataNotificationPushNotificationPermission: [''],
  deleteNotificationPushNotificationPermission: [''],

  // reports-sidenav
  reportsSideNavPermission: ['View_reports/package'],
  reportsMerchantReportsSubSideNavPermission: [
    'View_reports/merchant reports/module',
  ],
  reportsUserReportsSubSideNavPermission: ['View_reports/user reports/module'],
  reportsCustomerReportsSubSideNavPermission: [
    'View_reports/customer reports/module',
  ],
  reportsRecurrentReportsSubSideNavPermission: ['View_reports/sto payment plan report/package'],
  reportsTransactionReportsSubSideNavPermission: [
    'View_reports/transaction reports/module',
  ],
  // reports-customer reports
  viewDataCustomerReportsPermission: [
    'View_reports/customer reports/data/entity',
  ],
  editDataCustomerReportPermission: [
    'Edit_reports/customer reports/data/entity',
  ],
  addDataCustomerReportPermission: ['Add_reports/customer reports/data/entity'],
  deleteDataCustomerReportPermission: [
    'Delete_reports/customer reports/data/entity',
  ],
  viewDataCustomerReportTotalCustomerReportPermission: [
    'View_reports/customer reports/total customer report/report',
  ],
  viewDataCustomerReportCustomerActionsReportPermission: [
    'View_reports/customer reports/customer actions report/report',
  ],

  // cancellation report view permission
  viewCancellationReportSubSideNavPermission:[
    'View_reports/sto cancel payment report/package'
  ],
  // reports-merchant reports
  editDataMerchantReportPermission: [
    'Edit_reports/merchant reports/data/entity',
  ],
  addDataMerchantReportPermission: ['Add_reports/merchant reports/data/entity'],
  viewDataMerchantReportPermission: [
    'View_reports/merchant reports/data/entity',
  ],
  deleteDataMerchantReportPermission: [
    'Delete_reports/merchant reports/data/entity',
  ],

  viewMerchantReportTotalMerchantReportPermission: [
    'View_reports/merchant reports/total merchant report/report',
  ],
  // not needed
  viewMerchantReportTotalVoucherReportPermission: [''],
  viewMerchantReportMerchantDiscountReportPermission: [''],
  viewMerchantReportEZCashMerchantReportPermission: [''],

  // reports-transaction reports
  // only this total transaction report,other reports not needed
  viewTransactionReportTotalTransactionReportPermission: [
    'View_reports/transaction reports/total transaction report/report',
  ],
  viewTransactionReportDialogBillPaymentsReportPermission: [
    'View_reports/transaction reports/dialog bill payments report/report',
  ],
  viewTransactionReportEZCashBatchPaymentReportPermission: [
    'View_reports/transaction reports/ezy cash batch payment report/report',
  ],
  viewTransactionReportMerchantUserCreditCardBatchPaymentReportPermission: [
    'View_reports/transaction reports/merchant user credit card batch payment report/report',
  ],
  viewTransactionReportDailyCommissionReportPermission: [
    'View_reports/transaction reports/daily commission report/report',
  ],
  viewTransactionReportSlipTransferFeeDailyMerchantWiseReportPermission: [
    'View_reports/transaction reports/slip transfer fee daily merchant wise report/report',
  ],
  viewTransactionReportSettlementReportPermission: [
    'View_reports/transaction reports/settlement report/report',
  ],
  viewTransactionReportTotalDailyCommissionReportPermission: [
    'View_reports/transaction reports/total daily commission report/report',
  ],
  viewTransactionReportCreditCardBatchPaymentReportPermission: [
    'View_reports/transaction reports/credit card batch payment report/report',
  ],
  viewTransactionReportMerchantUserSettlementReportPermission: [
    'View_reports/transaction reports/merchant user settlement report/report',
  ],
  viewTransactionReportOutstandingBalanceAndCompletedReportPermission: [
    'View_reports/transaction reports/outstanding balance and completed report/report',
  ],
  viewTransactionReportMerchantUserEzCashBatchPaymentReportPermission: [
    'View_reports/transaction reports/merchant user ezy cash batch payment report/report',
  ],
  viewTransactionReportReversalTransactionReportPermission: [
    'View_reports/transaction reports/reversal transaction report/report',
  ],
  viewTransactionReportTotalVoucherTransactionReportPermission: [''],
  viewTransactionReportGeniePaymentAdviceReportPermission: [''],
  viewTransactionReportEZCashSettlementReportPermission: [''],
  viewTransactionReportCommissionSummaryReportPermission: [''],
  viewTransactionReportCustomerTransactionReportPermission: [''],
  viewTransactionReportDiscountedTransactionReportPermission: [''],

  // reports-user reports
  viewUserReportTotalUserReportPermission: [
    'View_reports/user reports/total user report/report',
  ],
  viewUserReportUserActionsReportPermission: [
    'View_reports/user reports/user actions report/report',
  ],
  viewUserReportNotLoggedinUserReportPermission: [
    'View_reports/user reports/not logged in user report/report',
  ],
  // not needed
  viewUserReportSODReportPermission: [''],

  // security //not needed
  securitySideNavPermission: [''],

  // transactions
  transactionsSideNavPermission: ['View_transactions/package'],
  transactionsMyTransactionsSubSideNavPermission: [
    'View_transactions/my transactions/module',
  ],
  // transactions-my transactions-data
  viewDataTransactionsMyTransactionsPermission: [
    'View_transactions/my transactions/data/entity',
  ],
  editDataTransactionsMyTransactionsPermission: [
    'Edit_transactions/my transactions/data/entity',
  ],
  deleteDataTransactionsMyTransactionsPermission: [
    'Delete_transactions/my transactions/data/entity',
  ],
  // transactions-my transactions-field
  viewFieldReverseTransactionMyTransactionsPermission: [
    'View_transactions/my transactions/reverse/field',
  ],
  viewFieldCompleteTransactionMyTransactionsPermission: [
    'View_transactions/my transactions/complete/field',
  ],
  viewFieldModifyTransactionMyTransactionsPermission: [
    'View_transactions/my transactions/modify/field',
  ],

  // recurrent plans
  viewRecurrentPlanPackagePermission: [
    'View_recurring/plan/package',
  ],
  cancelRecurrentPlanPermission: [
    'View_recurring/plan/cancel/module',
  ],
  fileUploadStatusReport:['View_reports/file upload status report/package'],
  // QR //not needed
  QRSideNavPermission: [''],
  QRCodesSubSideNavPermission: [''],

  viewDataQRCodesPermission: [''],
  addDataQRCodesPermission: [''],
  editDataQRCodesPermission: [''],
  deleteDataQRCodesPermission: [''],
};
