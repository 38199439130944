<section class="mat-dialog gap-remove-modal">
  <h5 class="gap-remove-modal__title">{{ title }}</h5>
  <section class="my-4 d-flex align-items-center">
    <p class="mat-dialog__message">
      {{ message }}
    </p>
  </section>
  <div
    *ngIf="!showOkButton"
    class="d-flex justify-content-start mat-dialog__btn-group"
  >
    <button
      mat-flat-button
      class="gap-btn gap-btn--md gap-btn--danger mr-3"
      (click)="onYesButtonClicked(); isClickedOnce = true"
      id="acceptTheConfirmationBox"
      [class.disabled]="isClickedOnce"
    >
      {{ confirmButtonText }}
    </button>
    <button
      mat-stroked-button
      (click)="onNoButtonClicked()"
      class="gap-btn gap-btn--md"
      id="declineTheConfirmationBox"
      tabindex="1"
    >
      {{ cancelButtonText }}
    </button>
  </div>
</section>
