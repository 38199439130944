import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Features } from '..';
import { LoginState } from '../reducers/LoginReducer';

export const selectLoginState = createFeatureSelector<LoginState>(
  Features.Login
);

export const selectSuccessLoginState = createSelector(
  selectLoginState,
  (state: LoginState) => state.list
);

export const selectEmailState = createSelector(
  selectLoginState,
  (state: LoginState) => state.email
);

export const selectPasswordResetState = createSelector(
  selectLoginState,
  (state: LoginState) => state.passwordResetToken
);

export const selectFailureLoginState = createSelector(
  selectLoginState,
  (state: LoginState) => state.hasLoadingError
);

export const selectLoginStatusState = createSelector(
  selectLoginState,
  (state: LoginState) => state.loginStatus
);

export const selectOtpRefState = createSelector(
  selectLoginState,
  (state: LoginState) => state.otpReferenceId
)

export const selectMaskedPhoneNumberState = createSelector(
  selectLoginState,
  (state: LoginState) => state.maskedPhoneNumber
)

export const selectMobileNumberState = createSelector(
  selectLoginState,
  (state: LoginState) => state.mobileNumber
)
export const selectErrorMessageState = createSelector(
  selectLoginState,
  (state: LoginState) => state.errorMessage
)