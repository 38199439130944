import { ActionReducer, INIT } from '@ngrx/store';
import { RootState } from '.';
import { LOGIN_STATE } from './reducers/LoginReducer';

// storing the state in the localstorage when the user refresh the browser to update the memory after the refresh
export const stateMetaReducer = (
  reducer: ActionReducer<RootState>
): ActionReducer<RootState> => {
  return (state, action) => {
    if (action.type === INIT) {
      const storageValue = localStorage.getItem('state');
      if (storageValue) {
        try {
          const localState = JSON.parse(storageValue);
          localState["login"]["loginStatus"] = LOGIN_STATE.LOGIN;
          return localState;
        } catch {
          localStorage.removeItem('access_token');
        }
      }
    }
    const nextState = reducer(state, action);
    localStorage.setItem('state', JSON.stringify(nextState));
    return nextState;
  };
};
