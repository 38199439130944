import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
// store
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import player from 'lottie-web';
// lottie
import { LottieModule } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { BlankComponent } from './scaffoldings/blank/blank.component';
import { ItemLayoutComponent } from './scaffoldings/item-layout/item-layout.component';
import { LayoutOneComponent } from './scaffoldings/layout-one/layout-one.component';
import { HasPermissionModule } from './shared/directives/has-permission/has-permission.module';
import { SharedModule } from './shared/shared.module';
import { effects, metaReducers, reducers } from './store';

// Note we need a separate function as it's required by the AOT compiler.
export function playerFactory(): any {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    BlankComponent,
    LayoutOneComponent,
    ItemLayoutComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HasPermissionModule,
    // store
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
