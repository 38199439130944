import { Action } from '@ngrx/store';

export enum MerchantActivationDeactivationActionTypes {
  SET_OPTION = '[bulk merchant] set option',
  CLEAR_OPTION = '[bulk merchant] clear option',

  SET_FILTER_CRITERIA = '[bulk merchant] set filter criteria',
  CLEAR_FILTER_CRITERIA = '[bulk merchant] clear filter criteria',

  SET_SEARCH_CRITERIA = '[bulk merchant] set search criteria',
  CLEAR_SEARCH_CRITERIA = '[bulk merchant] clear search criteria',

  SET_SELECTS_RESULTS_OF_SEARCH_CRITERIA = '[bulk merchant] selects results of search criteria',
  CLEAR_SELECTS_RESULTS_OF_SEARCH_CRITERIA = '[bulk merchant] clear results of search criteria',

  SET_SELECT_ALL_OPTION = '[bulk merchant] set select all option',
  CLEAR_SELECT_ALL_OPTION = '[bulk merchant] clear select all option',

  SET_MERCHANT_DATA = '[bulk merchant] set merchant data',
  CLEAR_MERCHANT_DATA = '[bulk merchant] clear merchant data',
  CLEAR_ALL_MERCHANT_DATA = '[bulk merchant] clear all merchant data',
}

export class SetOptionAction implements Action {
    readonly type = MerchantActivationDeactivationActionTypes.SET_OPTION;
    constructor(public payload: string) {}
}

export class ClearOptionAction implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.CLEAR_OPTION;
  constructor() {}
}

export class SetFilterCriteriaAction implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.SET_FILTER_CRITERIA;
  constructor(public payload: string) {}
}

export class ClearFilterCriteriaAction implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.CLEAR_FILTER_CRITERIA;
  constructor(public payload: any) {}
}

export class SetSearchCriteriaAction implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.SET_SEARCH_CRITERIA;
  constructor(public payload: string) {}
}

export class ClearSearchCriteriaAction implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.CLEAR_SEARCH_CRITERIA;
  constructor() {}
}

export class SetSelectsResultsOfSearchCriteria implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.SET_SELECTS_RESULTS_OF_SEARCH_CRITERIA;
  constructor(public payload: boolean) {}
}

export class ClearSelectsResultsOfSearchCriteria implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.CLEAR_SELECTS_RESULTS_OF_SEARCH_CRITERIA;
  constructor() {}
}

export class SetSelectAllAction implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.SET_SELECT_ALL_OPTION;
  constructor(public payload: any) {}
}

export class ClearSelectAllOption implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.CLEAR_SELECT_ALL_OPTION;
  constructor() {}
}

export class SetMerchantDataAction implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.SET_MERCHANT_DATA;
  constructor(public payloadID: string, public payloadName: string) {
  }
}

export class ClearMerchantDataAction implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.CLEAR_MERCHANT_DATA;
  constructor(public payloadID: string) {
  }
}

export class ClearAllMerchantDataAction implements Action {
  readonly type = MerchantActivationDeactivationActionTypes.CLEAR_ALL_MERCHANT_DATA;
  constructor() {}
}

export type MerchantActivationDeactivationActions =
                SetOptionAction |
                ClearOptionAction |
                SetFilterCriteriaAction |
                ClearFilterCriteriaAction |
                SetSearchCriteriaAction |
                ClearSearchCriteriaAction |
                SetSelectsResultsOfSearchCriteria |
                ClearSelectsResultsOfSearchCriteria |
                SetSelectAllAction |
                ClearSelectAllOption |
                SetMerchantDataAction |
                ClearMerchantDataAction |
                ClearAllMerchantDataAction;
