<section class="gap-rpm">
    <div class="d-flex mt-2 mb-4">
        <span class="gap-rpm__warning-symbol mr-3">⚠️</span>
        <p class="gap-rpm__txt">Your password has expired and must be<br/> changed.</p>
    </div>
    <section class="d-flex">
        <button mat-flat-button class="gap-btn gap-btn--width-small gap-btn--primary mr-4" (click)="resetPassword()">
            RESET
        </button>
        <button mat-button class="gap-btn gap-btn--width-small gap-btn--white-transparent-bg" (click)="closeModal()">
            CANCEL
        </button>
    </section>
</section>
