import { HttpErrorResponse } from '@angular/common/http';
import { Directive, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { SnackBarService } from 'src/app/core/services/snack-bar/snack-bar.service';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[gapIdleTimeout]',
})
export class IdleTimeoutDirective implements OnInit {
  interval: any;
  constructor(
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private router: Router,
    private dialogRef: MatDialog
  ) {}

  ngOnInit(): void {
    this.restartInterval();
  }

  /**
   * Listening to Mouse events
   */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e): void {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken && accessToken !== null) {
      this.restartInterval();
    }
  }

  /**
   * Listening to keyboard events
   */
  @HostListener('document:keyup', ['$event'])
  onKeyBoardMove(e): void {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken && accessToken !== null) {
      this.restartInterval();
    }
  }

  /**
   * @description Restarting the interval to logout while user is logged and idled for 15min
   */
  restartInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      clearInterval(this.interval);
      this.logoutUser();
    }, environment?.idleLogoutTime);
  }

  /**
   * @description Logout user after 15min idle time
   */
  logoutUser(): void {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken && (accessToken !== null || accessToken !== undefined)) {
      this.authService.logoutUser().subscribe(
        (res: any) => {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.snackBarService.show({
            message: 'You are logged out due to idle for ' + environment?.idleLogoutTime / (1000 * 60) + ' minutes',
          });
          this.dialogRef.closeAll();
        },
        (error: HttpErrorResponse) => {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.snackBarService.show({
            message: 'An error occurred while logging out',
            action: 'error',
          });
          this.dialogRef.closeAll();
        }
      );
    }
  }
}
