<header class="gap-header gap-header__space gap-header--fixed">
  <div class="d-flex gap-header--side-component">
    <div class="gap-header__space gap-header--side-component1">
      <div class="gap-header__divider"></div>
      <i
        class="material-icons gap-header__hamburger-menu sidebartoggler"
        aria-hidden="true"
        (click)="toggleMenu()"
        >menu</i
      >
      <div class="gap-header__divider"></div>
      <h6 class="gap-header__title ml-2">Genie Admin Portal</h6>
    </div>
    <div class="gap-header__space gap-header--side-component2 mr-5">
      <div class="d-flex align-items-center gap-header__profile-image">
        <span class="material-icons-outlined">
          account_circle
          </span>
      </div>
      <div
        class="hidden-xs hidden-sm gap-header__space gap-header__profile-data"
      >
        <span
          class="gap-header__text gap-header--text-alternate gap-subtitle-2"
          >{{ userName }}</span
        >
        <span
          class="gap-header__text gap-header--text-alternate gap-link gap-link--small gap-link--style"
          (click)="logoutHandler()"
          >Logout</span
        >
      </div>
    </div>
  </div>
</header>
