type tableHeader = Array<{ key: string, header: string }>;

export const paymentCommissionViewTableDTO: tableHeader = [
  {
    key: 'paymentOptionType',
    header: 'Payment option',
  },
  {
    key: 'chargeToType',
    header: 'Commission Bearer',
  },
  {
    key: 'integratedPaymentGatewayId',
    header: 'Payment IPG',
  },
  {
    key: 'commissionCategory',
    header: 'Commission Category',
  },
  {
    key: 'valueType',
    header: 'Commission Type',
  },
  {
    key: 'region',
    header: 'Region',
  },
  {
    key: 'startRange',
    header: 'Minimum Value',
  },
  {
    key: 'endRange',
    header: 'Maximum Value',
  },
  {
    key: 'tierSurchargeType',
    header: 'Commission Calculation Method',
  },
  {
    key: 'actualValue',
    header: 'Commission Value',
  },
];
