import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'gap-confirm-remove',
  templateUrl: './confirm-remove.component.html'
})
export class ConfirmRemoveComponent implements OnInit {
  message = '';
  title = '';
  imageSrc = '';
  subscribeObject: any = null;
  objectTwo: any = null;
  showOkButton = false;
  isClickedOnce;
  hasNoButtonFunction = false;

  component = '';
  confirmButtonText = 'REMOVE';
  cancelButtonText = 'CANCEL';
  okButtonText = 'Ok';

  // event emitters
  @Output() deleteConfirmValue = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<ConfirmRemoveComponent>) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  /**
   * Yes button function
   */
  yesButtonFunction(): void {}

  /**
   * Yes button function with param
   * @param {string} subscribe
   */
  yesButtonFunctionWithParam(subscribe): void {}

  /**
   * Yes button function with two param
   * @param {string} objOne
   * @param {string} objTwo
   */
  yesButtonFunctionWithTwoParam(objOne, objTwo): void {}

  /**
   * Determines whether yes button clicked on
   */
  onYesButtonClicked(): void {
    this.dialogRef.close(true);
  }

  /**
   * button function
   */
  noButtonFunction(): void {}

  /**
   * Determines whether no button clicked on
   */
  onNoButtonClicked(): void {
    this.dialogRef.close();
  }
}


