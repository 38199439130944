import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SidenavState } from '../reducers/SidenavReducer';

export const selectSidenavState = createFeatureSelector<SidenavState>(
    'Sidenav'
);

export const selectStatusState = createSelector(selectSidenavState,
    (state: SidenavState) => state.status
);

export const SidenavSelector = {
    selectStatusState
};
