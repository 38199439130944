import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { SnackBarService } from 'src/app/core/services/snack-bar/snack-bar.service';
import { ChangeSidenavAction } from 'src/app/store/actions/SidenavActions';
import { selectSuccessLoginState } from 'src/app/store/selectors/LoginSelector';

@Component({
  selector: 'gap-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  toggleMenuitem = false;
  isShowing = false;
  public userName;

  storeSub: Subscription;

  constructor(
    private authService: AuthService,
    private snackbarService: SnackBarService,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.toggleMenuResponsive();

    this.storeSub = this.store
      .select(selectSuccessLoginState)
      .subscribe((res: any) => {

        if (res.userName !== null) {
          this.userName = res.userName;
        } else {
          this.userName = 'Test';
        }
      });
  }

  /**
   *
   * Toggle menu based on browser responsive
   */
  toggleMenuResponsive(): void {
    if (window.screen.width >= 767) {
      this.toggleMenuitem = !this.toggleMenuitem;
    }
  }

  /**
   * onClick hamburger icon
   */
  toggleMenu(): void {
    if (window.innerWidth >= 767) {
      this.toggleMenuitem = !this.toggleMenuitem;
    }
    this.store.dispatch(new ChangeSidenavAction(this.toggleMenuitem));
  }

  /**
   *
   * logout handler
   */
  logoutHandler(): void {
    this.authService.logoutUser().subscribe(
      (res: any) => {
        localStorage.clear();
        this.router.navigate(['/']);
        this.snackbarService.show({message: 'You are logged out'});
      },
      (error: HttpErrorResponse) => {
        localStorage.clear();
        this.router.navigate(['/']);
        this.snackbarService.show({message: 'An error occurred while logging out', action: 'error'});
      }
    );
  }

  ngOnDestroy(): void {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
