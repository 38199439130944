import { MerchantActivationDeactivationActions, MerchantActivationDeactivationActionTypes } from '../actions/MerchantActivationDeactivationActions';

export interface BulkMerchantActivationDeactivationState {
  option: string;
  filterCriteria: string;
  searchCriteria: '';
  selectsResultsInSearchCriteria: boolean;
  allSelected: boolean;
  merchantsCount: number;
  merchantsData: Array<any>;
}

export const initialState: BulkMerchantActivationDeactivationState = {
    option: '',
    filterCriteria: '',
    searchCriteria: null,
    selectsResultsInSearchCriteria: false,
    allSelected: false,
    merchantsCount: 0,
    merchantsData: []
};

export function MerchantActivationDeactivationReducer(state: BulkMerchantActivationDeactivationState = initialState,
                                                      action: MerchantActivationDeactivationActions): any {
    switch (action.type) {
      case MerchantActivationDeactivationActionTypes.SET_OPTION:
        return {...state, option: action.payload};
      case MerchantActivationDeactivationActionTypes.CLEAR_OPTION:
        return {...state, option: ''};
      case MerchantActivationDeactivationActionTypes.SET_FILTER_CRITERIA:
        return {...state, filterCriteria: action.payload};
      case MerchantActivationDeactivationActionTypes.CLEAR_FILTER_CRITERIA:
        return {...state, filterCriteria: action.payload};
      case MerchantActivationDeactivationActionTypes.SET_SEARCH_CRITERIA:
        return {...state, searchCriteria: action.payload};
        // let occurrence = 0;
        // let index = null;
        // const array = [...state.searchCriteria];
        // const newObj = array.map((item) => {
        //   if (item.text.toLocaleUpperCase() === action.payload.toLocaleUpperCase()) {
        //     occurrence = item.count + 1;
        //     index = array.indexOf(item);
        //     return {...item, count: occurrence};
        //   }
        //   return item;
        // });
        // if (index !== null) {
        //   return {
        //     ...state,
        //     searchCriteria: newObj
        //   };
        // } else {
        //   return {
        //     ...state,
        //     searchCriteria: [...state.searchCriteria, { text: action.payload, count: 1}]
        //   };
        // }
      case MerchantActivationDeactivationActionTypes.CLEAR_SEARCH_CRITERIA:
        return {...state, searchCriteria: null};
      case MerchantActivationDeactivationActionTypes.SET_SELECTS_RESULTS_OF_SEARCH_CRITERIA:
        return {...state, selectsResultsInSearchCriteria: action.payload};
      case MerchantActivationDeactivationActionTypes.CLEAR_SELECTS_RESULTS_OF_SEARCH_CRITERIA:
        return {...state, selectsResultsInSearchCriteria: false};
      case MerchantActivationDeactivationActionTypes.SET_SELECT_ALL_OPTION:
        return {...state, allSelected: action.payload};
      case MerchantActivationDeactivationActionTypes.CLEAR_SELECT_ALL_OPTION:
        return {...state, allSelected: false};
      case MerchantActivationDeactivationActionTypes.SET_MERCHANT_DATA:
        return {
          ...state,
          merchantsData: [...state.merchantsData, {id: action.payloadID, name: action.payloadName}],
          merchantsCount: state.merchantsData.length + 1
        };
      case MerchantActivationDeactivationActionTypes.CLEAR_MERCHANT_DATA:
        return {
          ...state,
          merchantsData: state.merchantsData.filter(item => item.id !== action.payloadID),
          merchantsCount: state.merchantsData.length - 1
        };
      case MerchantActivationDeactivationActionTypes.CLEAR_ALL_MERCHANT_DATA:
        return {
          ...state,
          merchantsData: [],
          merchantsCount: 0
        };
      default:
        return state;
    }
}
