import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../../models/API/general/paginated-result.model';
import { PGConnectionMetadata } from '../../models/API/pg-connection/pg-connection-metadata.model';
import {
  AddPGConnectionResponse,
  ResponseWrapper,
  AddPGConnectionRequest,
  PGConnectionResponse,
  GetAllPGConnectionsResponse,
} from '../../models/API/pg-connection/pg-connection.model';
import { SearchMerchant } from '../../models/API/pg-connection/search-merchant/search-merchant.model';

@Injectable({
  providedIn: 'root',
})
export class PGConnectionService {
  constructor(private http: HttpClient) {}

  /*
   * get PG Metadata
   */
  getMetadata(): Observable<ResponseWrapper<PGConnectionMetadata>> {
    const url = 'adm/pg-connection/meta-data';
    return this.http.get<ResponseWrapper<PGConnectionMetadata>>(url);
  }

  /*
   * search merchants by name
   */
  searchMerchants(
    searchQuery: string
  ): Observable<ResponseWrapper<SearchMerchant>> {
    const url = `adm/pg-connection/search/merchants/${searchQuery}`;
    return this.http.get<ResponseWrapper<SearchMerchant>>(url);
  }

  /*
   * post pg connection details to add the connection
   */
  addPGConnection(
    data: AddPGConnectionRequest,
    isUpdate: boolean
  ): Observable<ResponseWrapper<AddPGConnectionResponse>> {
    const postData = {
      request: data,
    };
    const url = isUpdate
      ? `adm/pg-connection/edit`
      : `adm/pg-connection/add`;
    return this.http.post<ResponseWrapper<AddPGConnectionResponse>>(
      url,
      postData
    );
  }

  /*
   * get the details of a pg connection by id
   */
  getPGConnection(pgConnectionId: number): Observable<PGConnectionResponse> {
    const url = `adm/pg-connection/view/${pgConnectionId}`;
    return this.http.get<PGConnectionResponse>(url);
  }

  /*
   * get the list of all pg connections
   */
  getAllPGConnections(
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedResult<GetAllPGConnectionsResponse>> {
    const url = `adm/pg-connection/view_all?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<PaginatedResult<GetAllPGConnectionsResponse>>(url);
  }

  /*
   * search all pg connections by name
   */
  searchAllPGConnections(
    searchName: string,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedResult<GetAllPGConnectionsResponse>> {
    const url = `adm/pg-connection/search?name=${searchName}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<PaginatedResult<GetAllPGConnectionsResponse>>(url);
  }

  /*
   * delete pg connection by id
   */
  deletePGConnections(
    pgConnectionId: number
  ): Observable<ResponseWrapper<AddPGConnectionResponse>> {
    const url = `adm/pg-connection/delete/${pgConnectionId}`;
    return this.http.post<ResponseWrapper<AddPGConnectionResponse>>(url, {});
  }
}
