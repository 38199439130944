import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// material modules
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import player from 'lottie-web';
// external modules
import { LottieModule } from 'ngx-lottie';
// module components
import { ConfirmRemoveModule } from './common-popups/confirm-remove/confirm-remove.module';
import { EmptyComponent } from './components/empty/empty.component';
import { ExpandedBodyComponent } from './components/expanded-body/expanded-body.component';
import { ExpandedFooterComponent } from './components/expanded-footer/expanded-footer.component';
import { ExpandedHeaderComponent } from './components/expanded-header/expanded-header.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageCropComponent } from './components/image-crop/image-crop.component';
import { ImageUploadInputComponent } from './components/image-upload-input/image-upload-input.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ResultSummaryComponent } from './components/result-summary/result-summary.component';
import { SecondaryHeaderComponent } from './components/secondary-header/secondary-header.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SmartSearchComponent } from './components/smart-search/smart-search.component';
// directives
import { OptionsScrollDirective } from './directives/autocomplete-scroll/options-scroll.directive';
import { HasPermissionModule } from './directives/has-permission/has-permission.module';
import { IdleTimeoutDirective } from './directives/idel-timeout/idle-timeout.directive';

// Note we need a separate function as it's required by the AOT compiler.
export function playerFactory(): any {
  return player;
}

@NgModule({
  declarations: [
    HeaderComponent,
    SideNavComponent,
    SecondaryHeaderComponent,
    ResultSummaryComponent,
    ExpandedHeaderComponent,
    ExpandedBodyComponent,
    NotificationComponent,
    LoaderComponent,
    EmptyComponent,
    PageNotFoundComponent,
    ExpandedFooterComponent,
    PaginationComponent,
    SmartSearchComponent,
    OptionsScrollDirective,
    InfoBoxComponent,
    ImageCropComponent,
    ImageUploadInputComponent,
    IdleTimeoutDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ConfirmRemoveModule,
    // material modules
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTooltipModule,
    MatProgressBarModule,

    MatPaginatorModule,
    MatTableModule,
    // external modules
    LottieModule.forRoot({ player: playerFactory }),
    HasPermissionModule,
  ],
  exports: [
    HeaderComponent,
    SideNavComponent,
    SecondaryHeaderComponent,
    ResultSummaryComponent,
    ExpandedHeaderComponent,
    ExpandedBodyComponent,
    NotificationComponent,
    LoaderComponent,
    EmptyComponent,
    PageNotFoundComponent,
    ExpandedFooterComponent,
    PaginationComponent,
    SmartSearchComponent,
    InfoBoxComponent,
    OptionsScrollDirective,
    ImageUploadInputComponent,
    IdleTimeoutDirective
  ],
})
export class SharedModule {}
