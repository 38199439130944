import { Action } from '@ngrx/store';

export enum SidenavActionTypes {
  CHANGE_SIDENAV = '[sidenav] change sidenav',
}

export class ChangeSidenavAction implements Action {
    readonly type = SidenavActionTypes.CHANGE_SIDENAV;
    constructor(public payload: boolean) {}
}

export type SidenavActions = ChangeSidenavAction;
