import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BlankComponent} from './scaffoldings/blank/blank.component';
import {ItemLayoutComponent} from './scaffoldings/item-layout/item-layout.component';
import {LayoutOneComponent} from './scaffoldings/layout-one/layout-one.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: BlankComponent,
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot-password',
    component: BlankComponent,
    loadChildren: () => import('./modules/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'password-reset-flow',
    component: BlankComponent,
    loadChildren: () => import('./modules/password-reset-flow/password-reset-flow.module').then(m => m.PasswordResetFlowModule)
  },
  {
    path: 'reset-password',
    component: BlankComponent,
    loadChildren: () => import('./modules/change-password/change-password.module').then((m) => m.ChangePasswordModule),
  },
  {
    path: 'dashboard',
    component: LayoutOneComponent,
    data: {
      breadcrumb: 'Dashboard',
    },
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'dashboard',
    component: ItemLayoutComponent,
    data: {
      breadcrumb: 'Dashboard',
    },
    loadChildren: () => import('./modules/merchant/merchant.module').then((m) => m.MerchantModule),
  },
  {
    path: 'dashboard',
    component: ItemLayoutComponent,
    data: {
      breadcrumb: 'Dashboard',
    },
    loadChildren: () => import('./modules/outlets/outlets.module').then((m) => m.OutletsModule),
  },
  {
    path: 'dashboard',
    component: ItemLayoutComponent,
    data: {
      breadcrumb: 'Dashboard',
    },
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'dashboard',
    component: ItemLayoutComponent,
    data: {
      breadcrumb: 'Dashboard',
    },
    loadChildren: () => import('./modules/report/report.module').then((m) => m.ReportModule),
  },
  {
    path: 'dashboard',
    component: ItemLayoutComponent,
    data: {
      breadcrumb: 'Dashboard',
    },
    loadChildren: () => import('./modules/recurring-payments/recurring-payments.module').then((m) => m.RecurringPaymentsModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
