<div class="row">
  <section class="col-12 gap-file-inp">
    <mat-label style="font-size: 13px" class="">Logo</mat-label>
    <div class="mt-2 gap-file-inp__inner-block">
      <input
        mat-button
        type="file"
        [accept]="acceptTypes"
        id="uploadSPLogo"
        (change)="processFile($event)"
      />
      <label for="uploadSPLogo" class="mr-3 gap-file-upload gap-file-inp__abs">
        <span class="material-icons-outlined mr-2">file_upload</span> Upload Image
      </label>
      <img
        *ngIf="imagePreview"
        [src]="imagePreview"
        alt = "image preview"
        class="gap-image-preview"
      />
      <div *ngIf="imagePreview" class="gap-file-inp__overlay"></div>
      <span class="pt-2 gap-file-inp__file-name" *ngIf="!imageEditMode">{{
        selectedFileName || "No file chosen"
      }}</span>
    </div>
    <mat-error *ngIf="maxSizeViolated" class="mt-3 gap-file-inp__error"
      >File Size Limit Exceeded. Max file size : 250KB</mat-error
    >
  </section>
</div>
