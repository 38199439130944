import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'gap-password-reset-modal',
  templateUrl: './password-reset-modal.component.html',
  styleUrls: ['./password-reset-modal.component.scss']
})
export class PasswordResetModalComponent implements OnInit {

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<PasswordResetModalComponent>) { }

  ngOnInit(): void {
  }

  /**
   * go to reset password route
   */
  resetPassword(): void {
    this.closeModal();
    this.router.navigate(['/password-reset-flow']);
  }

  /**
   * close modal
   */
  closeModal(): void {
    this.dialogRef.close();
  }

}
