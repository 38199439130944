import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'gap-smart-search',
  templateUrl: './smart-search.component.html'
})
export class SmartSearchComponent implements OnInit, OnDestroy {

  @ViewChild('smartSearchInput', { static: true }) smartSearchInput: ElementRef;

  @Input() placeholder = 'search';

  @Output() onSearchChange = new EventEmitter<string>();

  searchSubscribe: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.searchInputListener();
  }

  /**
   * search input listener
   */
   searchInputListener(): void {
    this.searchSubscribe = fromEvent(this.smartSearchInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        filter((res) => res.length >= 0),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((searchString: string) => {
        this.onSearchChange.emit(searchString);
      });
  }

  ngOnDestroy(): void {
    if (this.searchSubscribe) {
      this.searchSubscribe.unsubscribe();
    }
  }

}
