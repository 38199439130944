import { RouteInfo } from '../models/UI/sidebar.metadata';
import { PERMISSIONS } from './permission';

export const MENU_ITEMS: RouteInfo[] = [
  {
    path: './',
    title: 'Merchants',
    icon: 'point_of_sale',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: PERMISSIONS.merchantSideNavPermission,
    submenu: [
      {
        path: 'merchant/my-merchants',
        title: 'My Merchants',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
        permissions: PERMISSIONS.merchantMyMerchantSubSideNavPermission,
      },
      {
        path: 'merchant/pg-connection',
        title: 'PG Connections',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
        permissions: PERMISSIONS.merchantPgConnectionSubSideNavPermission,
      },
      {
        path: 'merchant/payee-types',
        title: 'Payee Types',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
        permissions: PERMISSIONS.merchantPayeeTypesSubSideNavPermission,
      },
      {
        path: 'merchant/merchant-discounts',
        title: 'Merchant Discounts',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
        permissions: PERMISSIONS.merchantDicountsSubSideNavPermission,
      },
    ],
  },
  {
    path: './',
    title: 'Outlets',
    icon: 'storefront',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: PERMISSIONS.outletSideNavPermission,
    submenu: [
      {
        path: 'outlets/my-outlets',
        title: 'My Outlets',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
        permissions: PERMISSIONS.outletsMyOutletsSubSideNavPermission,
      },
      {
        path: 'outlets/counters',
        title: 'Counters',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
        permissions: PERMISSIONS.outletsCountersSubSideNavPermission,
      },
    ],
  },
  {
    path: './',
    title: 'Users',
    icon: 'group',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: PERMISSIONS.usersSideNavPermission,
    submenu: [
      {
        path: 'user/my-users',
        title: 'My Users',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
        permissions: PERMISSIONS.usersMyUsersSubSideNavPermission,
      },
      {
        path: 'user/roles-privileges',
        title: 'Roles and Privileges',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
        permissions: PERMISSIONS.usersRolesAndPrivilegesSubSideNavPermission,
      },
    ],
  },
  {
    path: './',
    title: 'Reports',
    icon: 'description',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: PERMISSIONS.reportsSideNavPermission,
    submenu: [
      {
        path: 'transaction-reports',
        title: 'Transaction Reports',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
        permissions: PERMISSIONS.reportsTransactionReportsSubSideNavPermission,
      }
      // {
      //   path: 'file-upload-status-report',
      //   title: 'File Upload Status Report',
      //   icon: '',
      //   class: '',
      //   label: '',
      //   labelClass: '',
      //   extralink: false,
      //   submenu: [],
      //   permissions: PERMISSIONS.fileUploadStatusReport,
      // },
      // {
      //   path: 'recurring-payment-plan-report',
      //   title: 'Recurring Payment Plan Report',
      //   icon: '',
      //   class: '',
      //   label: '',
      //   labelClass: '',
      //   extralink: false,
      //   submenu: [],
      //   permissions: PERMISSIONS.reportsRecurrentReportsSubSideNavPermission,
      // },
      // {
      //   path: 'recurring-payment-plan-cancellation-report',
      //   title: 'Recurring Payment Plan Cancellation Report',
      //   icon: '',
      //   class: '',
      //   label: '',
      //   labelClass: '',
      //   extralink: false,
      //   submenu: [],
      //   permissions: PERMISSIONS.viewCancellationReportSubSideNavPermission,
      // }
    ],
  }
  // {
  //   path: './',
  //   title: 'Recurring Payments',
  //   icon: 'description',
  //   class: 'has-arrow',
  //   label: '',
  //   labelClass: '',
  //   extralink: false,
  //   permissions: PERMISSIONS.reportsRecurrentReportsSubSideNavPermission,
  //   submenu: [
  //     {
  //       path: 'payment-plans',
  //       title: 'Payment Plans',
  //       icon: '',
  //       class: '',
  //       label: '',
  //       labelClass: '',
  //       extralink: false,
  //       submenu: [],
  //       permissions: PERMISSIONS.reportsRecurrentReportsSubSideNavPermission,
  //     }
  //   ],
  // }
];
