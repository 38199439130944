<section
  class="d-flex align-items-center justify-content-between gap-expanded-header"
>
  <span class="d-flex align-items-center gap-expanded-header__wrapper">
    <span
      class="material-icons-outlined d-flex align-items-center justify-content-center gmp-result-summary__icon"
      >{{ resultIcon }}</span
    >
    <h5 class="gap-expanded-header__title" matTooltip="{{ componentTitle }}">
      {{ componentTitle }}
    </h5>
  </span>
  <span>
    <button
      mat-icon-button
      aria-label="edit icon"
      class="gap-expanded-header__icon"
      [hasPermission]="editPermission"
      (click)="editHandler(itemID); $event.stopPropagation()"
      *ngIf="!showCancelOption"
    >
      <mat-icon matTooltip="Edit">edit</mat-icon>
    </button>
    <button
      mat-icon-button
      aria-label="remove icon"
      class="gap-expanded-header__icon"
      [hasPermission]="deletePermission"
      (click)="deleteHandler(itemID); $event.stopPropagation()"
      *ngIf="!showCancelOption"
    >
      <mat-icon matTooltip="Delete">delete</mat-icon>
    </button>
    <button
      mat-stroked-button
      class="gap-btn"
      (click)="cancelPaymentPlan(itemID, componentTitle); $event.stopPropagation()"
      [hasPermission]="cancelPermission"
      *ngIf="showCancelOption">
        Cancel Payment Plan
    </button>
  </span>
</section>
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
