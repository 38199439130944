<section class="gap-pnf-wrapper">
    <div class="gap-pnf-wrapper__inner-wrapper">
      <header class="mb-5">
          <img src = "{{imagePath}}app_logos/genie_logo.png" alt = "genie logo" class = "gap-pnf-wrapper__header-img"/>
          <h4 class="mt-3">Admin Portal</h4>
      </header>
      <ng-lottie [options]="options" [width]="50" [height]="50" class="gap-pnf-wrapper__image"></ng-lottie>
      <h1 class="mt-4 gap-pnf-wrapper__title">Page Not Found!</h1>
      <p class="gap-pnf-wrapper__description">We couldn't find the page you were looking for. Please make sure you have <br/> access to this page.</p>
      <a class="d-flex align-items-center justify-content-center mt-4 gap-link gap-link--style gap-link--basic" routerLink="/login">
        Go to Login
      </a>
    </div>
</section>