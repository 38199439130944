import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { ClearAllMerchantDataAction, ClearSelectAllOption, MerchantActivationDeactivationActionTypes } from '../actions/MerchantActivationDeactivationActions';

@Injectable()
export class MerchantActivationDeactivationEffects {
  constructor(
    private action$: Actions,
    private store: Store
  ) {}

  @Effect({ dispatch: false }) selectAllEffect$ = this.action$.pipe(
    ofType(MerchantActivationDeactivationActionTypes.SET_SELECT_ALL_OPTION),
    tap((action) => {
      this.store.dispatch(new ClearAllMerchantDataAction());
    })
  );

  @Effect({ dispatch: false }) flowTypeEffect$ = this.action$.pipe(
    ofType(MerchantActivationDeactivationActionTypes.SET_OPTION),
    tap((action) => {
      this.store.dispatch(new ClearAllMerchantDataAction());
      this.store.dispatch(new ClearSelectAllOption());
    })
  );

  @Effect({ dispatch: false }) searchCriteriaEffect$ = this.action$.pipe(
    ofType(MerchantActivationDeactivationActionTypes.SET_SEARCH_CRITERIA),
    tap((action) => {
      this.store.dispatch(new ClearAllMerchantDataAction());
    })
  );
}
