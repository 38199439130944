import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'gap-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {

  imagePath = environment.imagePath;
  options: AnimationOptions;

  constructor() { }

  ngOnInit(): void {
    this.options = {
      path: this.imagePath + 'page-not-found.json',
      autoplay: true,
      loop: true
    };
  }

}
