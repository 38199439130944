import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { UserDataService } from 'src/app/core/services/user/user-data.service';
import { SnackBarService } from 'src/app/core/services/snack-bar/snack-bar.service';
import { LoginResponse, TokenVerifyResponse } from 'src/app/modules/login/login/dtos/login-response';
import { PasswordResetModalComponent } from 'src/app/modules/login/password-reset-modal/password-reset-modal.component';
import * as LoginActions from '../actions/LoginActions';
import { select, Store } from '@ngrx/store';

@Injectable()
export class LoginEffects {
  constructor(
    private action$: Actions,
    private router: Router,
    private authService: AuthService,
    private userDataService: UserDataService,
    private snackBarService: SnackBarService,
    private titleService: Title,
    private dialog: MatDialog,
    private store: Store
  ) {}

  @Effect({ dispatch: false }) successTokenVerifyAction$ = this.action$.pipe(
    ofType(LoginActions.SuccessTokenVerifyAction),
    tap((action) => {
      this.successLoginV2(action.payload);
    })
  );

  @Effect({ dispatch: false }) successLogin$ = this.action$.pipe(
    ofType(LoginActions.SuccessLoginAction),
    tap((action) => {
      const tokenVerifyPayload: any = {
  
      };
      this.store.dispatch(
        LoginActions.RequestTokenVerifyAction({
          payload:tokenVerifyPayload,
        })
      );
     this.successLogin(action.payload);
    })
  );

  @Effect({ dispatch: false }) loginFail$ = this.action$.pipe(
    ofType(LoginActions.FailureLoginAction),
    tap((action) => {
      //
      this.failureLogin(action.payload);
    })
  );

  @Effect({ dispatch: false }) loginAttemptFail$ = this.action$.pipe(
    ofType(LoginActions.FailureLoginAttemptAction),
    tap((action) => {
      //
      this.failureLogin(action.payload);
    })
  );

  @Effect({ dispatch: false }) failValidateOtp$ = this.action$.pipe(
    ofType(LoginActions.FailureOTPValidateAction),
    tap((action) => {
      //
      this.failureOtp(action.payload);
    })
  );

  @Effect({ dispatch: false }) failResendOtp$ = this.action$.pipe(
    ofType(LoginActions.FailureResendOtptAction),
    tap((action) => {
      //
      this.failureOtp(action.payload);
    })
  );



  @Effect() loadLogin$ = this.action$.pipe(
    ofType(LoginActions.RequestLoginAction),
    switchMap(
      (action) =>
      this.authService.authenticateUserV2(action.payload).pipe(
        map((res) => {
          if (res.status === 'failure') {
            // load password reset notification modal if password is expired
            if (res.code === '5012') {
              this.dialog.open(PasswordResetModalComponent, {});
              return LoginActions.SetPasswordResetTokenAction({
                payload: res.passwordRestToken,
              });
            }
            return LoginActions.FailureLoginAttemptAction({ payload: res });
          } else {
            return LoginActions.SuccessLoginAttemptAction({
              email: action.payload.username,
              response: res,
            });
          }
        }),
        catchError((error) =>
          of(LoginActions.FailureLoginAttemptAction({ payload: error }))
        )
      )
    )
  );




@Effect() validateOtp$ = this.action$.pipe(
  ofType(LoginActions.RequestOTPValidateAction),
  switchMap(
    (action) =>
    this.authService.verifyOtp(action.payload).pipe(
      map((res) => {
        if (res.status === 'failure') {
          // load password reset notification modal if password is expired
          return LoginActions.FailureOTPValidateAction({ payload: res });
        } else if (res.code==='0000'&& res.status==='success'){

          // console.log('EX 1');
          // this.getProfile$;
          // console.log('EX 2');

          return LoginActions.SuccessLoginAction({payload: res});
        }
      }),
      catchError((error) =>
        of(LoginActions.FailureOTPValidateAction({ payload: error }))
      )
    )
  )
);


@Effect() getProfile$ = this.action$.pipe(
  ofType(LoginActions.RequestTokenVerifyAction),
  switchMap(
    (action) =>
  this.userDataService.tokenVerify(action).pipe(
  tap(() => console.log("Token Verify Successful")),
  map((tokenRes) => {
    if (tokenRes.status === 'success') {
      console.log("Success OTP Verify");
      return LoginActions.SuccessTokenVerifyAction({ payload: tokenRes });
    } else {
      console.error("Token Verification Failed");
      return LoginActions.FailureTokenVerifyAction({ payload: tokenRes });
    }
  }

  ),
  )
  
  )
);


// @Effect() validateOtp$ = this.action$.pipe(
//   ofType(LoginActions.RequestOTPValidateAction),
//   switchMap(
//     (action) =>
//     this.authService.verifyOtp(action.payload).pipe(
//       map ( (res) => {

//         console.log("Login response",res);
//         if (res.status === 'failure') {
//           // load password reset notification modal if password is expired
//           return LoginActions.FailureOTPValidateAction({ payload: res });
//         } else if (res.code==='0000'&& res.status==='success'){
// console.log("Success OTP Verify")
//         }
//        }),
          
//       catchError((error) =>
//         of(LoginActions.FailureOTPValidateAction({ payload: error }))
//       )
//     )
//   ),

  // switchMap(
  //   (action) =>
  // this.authService.tokenVerify(action).pipe(
  // tap(() => console.log("Token Verify Successful")),
  // map((tokenRes) => {
  //   if (tokenRes.status === 'success') {
  //     console.log("Success OTP Verify");
  //     return LoginActions.SuccessLoginAction({ payload: tokenRes });
  //   } else {
  //     console.error("Token Verification Failed");
  //     return LoginActions.FailureOTPValidateAction({ payload: tokenRes });
  //   }
  // }),
  // catchError((tokenError) => {
  //   console.error("Token Verification Error:", tokenError);
  //   return of(LoginActions.FailureOTPValidateAction({ payload: tokenError }));
  // })
  // )
  
  // )


      




@Effect() resendOtp$ = this.action$.pipe(
  ofType(LoginActions.ResendOtpAction),
  switchMap(
    (action) =>
    this.authService.resendOtp(action.payload).pipe(
      map((res) => {
       
        if (res.status === 'failure') {
          return LoginActions.FailureResendOtptAction({payload: res});
        }else {
          return LoginActions.SuccessResendOtptAction({reseponse: res});
        }
        
      }),
      catchError((error) =>
        of(LoginActions.FailureResendOtptAction({ payload: error }))
      )
    )
  )
);


// @Effect() tokenVerify$ = this.action$.pipe(
//   ofType(LoginActions.TokenVerifyAction),
//   switchMap(
//     (action) =>
//   this.userDataService.tokenVerify(action.payload).pipe(
//   tap(() => console.log("Token Verify Successful")),
//   map((tokenRes) => {
//     if (tokenRes.status === 'success') {
//       console.log("Success OTP Verify");
//       return LoginActions.SuccessLoginAction({ payload: tokenRes });
//     } else {
//       console.error("Token Verification Failed");
//       return LoginActions.FailureOTPValidateAction({ payload: tokenRes });
//     }
//   }),
//   catchError((tokenError) => {
//     console.error("Token Verification Error:", tokenError);
//     return of(LoginActions.FailureOTPValidateAction({ payload: tokenError }));
//   })
// )

// )
// );

  successLogin(payload: LoginResponse): void {
    if (payload.status === 'failure') {
      this.snackBarService.show({
        message: payload.message,
        action: 'error',
      });
    } else {
      localStorage.setItem('access_token', payload.accessToken);
      localStorage.setItem('refresh_token', payload.refreshToken);


    }
  }

  successLoginV2(payload: TokenVerifyResponse): void {
    if (payload.status === 'failure') {
      this.snackBarService.show({
        message: payload.message,
        action: 'error',
      });
    } else {
      localStorage.setItem('userType', payload.userType);
      localStorage.setItem('userName', payload.userName);

      this.router.navigate(['/dashboard']);
      this.titleService.setTitle('Genie Admin Portal');
    }
  }

  failureLogin(error: any): void {
    // do not need to show error messsage in snackbar if password is expired
    if (error.code !== '5012') {
      this.snackBarService.show({
        message: error.message,
        action: 'error',
      });
    }
  }

  failureOtp(error: any): void {
    // do not need to show error messsage in snackbar if password is expired
    if (error.code !== '5016') {
      this.snackBarService.show({
        message: error.message,
        action: 'error',
      });
    }
  }

  failureResendOtp(error: any): void {
    // do not need to show error messsage in snackbar if password is expired
    if (error.code !== '5016' || error.code !== '5014' || error.code !== '5007') {
      this.snackBarService.show({
        message: error.message,
        action: 'error',
      });
    }
  }


}
