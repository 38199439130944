import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { LoginEffects } from './effects/LoginEffects';
import { MerchantActivationDeactivationEffects } from './effects/MerchantActivationDeactivationEffects';
import { PGConnectionEffects } from './effects/PGConnectionEffects';
import { LoginReducer, LoginState } from './reducers/LoginReducer';
import { MerchantActivationDeactivationReducer } from './reducers/MerchantActivationDeactivationReducer';
import {
  PGConnectionReducer,
  PGConnectionState,
} from './reducers/PGConnectionReducer';
import { SidenavReducer } from './reducers/SidenavReducer';
import { stateMetaReducer } from './state-refresh.reducer';

export enum Features {
  PGConnection = 'pgConnection',
  Login = 'login',
}

export interface RootState {
  pgConnection: PGConnectionState;
  login: LoginState;
  Sidenav: any;
  MerchantActivationDeactivation: any;
}

export const reducers: ActionReducerMap<RootState> = {
  login: LoginReducer,
  pgConnection: PGConnectionReducer,
  Sidenav: SidenavReducer,
  MerchantActivationDeactivation: MerchantActivationDeactivationReducer
};

export const metaReducers: MetaReducer[] = [
  stateMetaReducer
];

export const effects = [LoginEffects, PGConnectionEffects, MerchantActivationDeactivationEffects];
