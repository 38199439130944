<section class="d-flex align-items-center justify-content-between gap-notification__inner-wrapper">
    <div class="d-flex align-items-center">
        <div class="gap-notification__icon mr-3" *ngIf="imageType">
            <ng-lottie [options]="options" [width]="300" [height]="600" class="gap-notification__icon-inner"></ng-lottie>
        </div>
        <h5 class="mb-0 gap-notification__title">{{data.message}}</h5>
    </div>
    <button mat-icon-button color="accent" aria-label="remove panel button icon" (click)="closePanel()">
        <mat-icon>close</mat-icon>
    </button>
</section>