<div class="container-fluid gap-layout" id="main-wrapper">
    <gap-header (menuState)='toggleMenuShow($event)' (HideSideNav)='HideSideMenuOnClick($event)'></gap-header>
    <div class="gap-layout__body row">
        <div class="gap-layout__left-sidebar gap-scroll-bar">
            <gap-side-nav [subMenuState]="subMenuState" [HideMenu]="HideMenu"></gap-side-nav>
        </div>
        <div class="col gap-layout__content gap-layout--page-wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>