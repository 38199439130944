import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  GetNewTokenByRefreshTokenRequest,
  LoginRequest,
  ResendOtpRequest,
  Verify2FAOTPRequest,
} from 'src/app/modules/login/login/dtos/login-request';
import { LoginOtpResponse, LoginResponse } from 'src/app/modules/login/login/dtos/login-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loginURL = 'adm-enterprise/internal/login';
  loginURLV2 = 'adm-enterprise/external/login/v2';
  otpVerifyURL = 'adm-enterprise/external/otp/verify';
  resendOtpURL = 'adm-enterprise/resendOtp';
  logoutURL = 'adm-enterprise/logout';
  refreshAccessTokenURL = 'adm-enterprise/refresh-access';
  tokenVerifyUrl ='adm-enterprise/internal/token/verify';

  constructor(private http: HttpClient) {}

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    if (!token && (token === null || token === undefined)) {
      return false;
    }
    return true;

    // Check whether the token is expired and return
    // true or false
  }

  /**
   * login service
   */
  authenticateUser(payload: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(this.loginURL, payload);
  }

  /**
   * return bearer token from local storage
   */
  getToken(): string {
    return localStorage.getItem('access_token');
  }

  /**
   * delete bearer token from local storage
   */
  removeToken(): void {
    localStorage.removeItem('access_token');
  }

  /**
   * @description Logout User
   */
  logoutUser(): Observable<any> {
    let logoutRequest: any;
    return this.http.post<any>(this.logoutURL, logoutRequest);
  }

  /**
   * password reset API initiation
   * @param payload request body
   */
   resetPassword(payload): Observable<any> {
    const url = 'adm/reset-password';
    return this.http.post<any>(url, payload);
  }

  /*
   * retrieve refresh token from local storage
   * return refresh token
   */
  getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  /**
   *
   * @param payload refresh token
   * @description Get new token using refresh token
   */
  getNewAccessTokenFromRefreshToken(
    payload: GetNewTokenByRefreshTokenRequest
  ): Observable<any> {
    return this.http.post<any>(this.refreshAccessTokenURL, payload);
  }

  /**
   *
   * @param payload LoginRequest (username, password)
   * @description Login with two factor auth
   */
  authenticateUserV2(payload: LoginRequest): Observable<LoginOtpResponse> {

    return this.http.post<LoginOtpResponse>(this.loginURLV2, payload);
  }

  /**
   *
   * @param payload Verify2FAOTPRequest (otp_code, reference_id)
   * @description Verifies 2fa otp code and logs in
   */
  verifyOtp(payload: Verify2FAOTPRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(this.otpVerifyURL, payload)
  }

  resendOtp(payload: ResendOtpRequest): Observable<LoginOtpResponse> {
    return this.http.post<LoginOtpResponse>(this.resendOtpURL, payload)
  }

  resendVerifyOtp(payload: Verify2FAOTPRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(this.otpVerifyURL, payload)
  }


}
