<div class="container-fluid gap-layout" id="main-wrapper">
    <gap-header></gap-header>
    <div class="gap-layout__body row">
        <div class="gap-layout__left-sidebar gap-scroll-bar">
            <gap-side-nav [subMenuState]="subMenuState" [HideMenu]="HideMenu"></gap-side-nav>
        </div>
        <div class="col gap-layout__content gap-layout--page-wrapper" [ngClass]="subMenuState? 'gap-layout__content--m-230':'gap-layout__content--m-86'">
            <gap-secondary-header></gap-secondary-header>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<gap-loader></gap-loader>