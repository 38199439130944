import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gap-image-upload-input',
  templateUrl: './image-upload-input.component.html'
})
export class ImageUploadInputComponent implements OnInit, OnChanges {

  @Input() acceptTypes ? = 'image/*';
  // only to display the image in the background in edit forms
  @Input() imageEditMode ? = '';
  @Output() imageOutput = new EventEmitter<any>();

  // image upload
  selectedFileName: string;
  selectedFile: any;
  uploadedImage: File;
  maxSizeViolated: boolean;
  imagePreview = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.imagePreview = this.imageEditMode;
    this.selectedFileName = this.imageEditMode;
  }

  /**
   * Process image
   * @param imageInput uploaded image
   */
   processFile(imageInput: any): void {
    if (imageInput.target.files.length > 0) {
      this.selectedFileName = imageInput.target.files[0].name;
      this.selectedFile = imageInput;
    }
    if (imageInput.target.files[0].size < 250001) {
      this.maxSizeViolated = false;
      this.uploadedImage = imageInput.target.files[0];
      this.generateImagePreview(imageInput);
      this.imageOutput.emit(this.uploadedImage);
    }
    else {
      this.maxSizeViolated = true;
      imageInput.target.value = null;
      this.selectedFileName = '';
      this.selectedFile = '';
      this.imagePreview = '';
    }
  }

  generateImagePreview(image: any): void {
    const file = (image.target as HTMLInputElement).files[0];
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

}
