import { Component, Input, OnInit } from '@angular/core';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { AddPaymentTerms } from 'src/app/core/models/UI/merchant/addMerchantPaymentTerms';
import { payTermTableDisplay } from 'src/app/core/mock-data/merchant/add-merchant-payment-terms';
import { AddPaymentCommission } from 'src/app/core/models/UI/merchant/addPaymentCommission';
import { AddPartner } from 'src/app/core/models/UI/merchant/addPartner';
import { MatDialog } from '@angular/material/dialog';
import { ViewTablePopupComponent } from 'src/app/modules/merchant/components/my-merchants/view-table-popup/view-table-popup.component';
import { SnackBarService } from 'src/app/core/services/snack-bar/snack-bar.service';
import { paymentCommissionViewTableDTO } from 'src/app/core/models/tables/paymentCommissionViewTableDTO';
import { partnershipDetailTableDTO } from 'src/app/core/models/tables/partnershipDetailViewTableDTO';

@Component({
  selector: 'gap-expanded-body',
  templateUrl: './expanded-body.component.html'
})
export class ExpandedBodyComponent implements OnInit {
  @Input() merchantSecondaryTitle;
  @Input() merchantStartDate;
  @Input() merchantEndDate;
  @Input() merchantID;
  @Input() merchant;

  data: any;
  medaData: any = [];
  industryObj: any = {};
  showTable = false;
  showCommissionTable = false;
  dataSource: AddPaymentTerms[];
  dataSourceCommission: AddPaymentCommission[];
  dataSourcePartner: AddPartner[];
  displayedColumns = payTermTableDisplay;
  displayedColumnsKeys: string[];

  displayedCommissionColumns = paymentCommissionViewTableDTO;
  displayedCommissionColumnsKeys: string[];

  partenrShipDetailDisplayedColumns = partnershipDetailTableDTO;
  partenrShipDetailDisplayedColumnsKeys: string[];

  merchantType: any = [];
  resultsLength = 10; // pagin length
  paymentOption: any = [];
  bankDetails: any = [];
  businessTypeDetails: any = {};
  signatorDirectors: any = [];
  merchantTy: string;
  comcatList: any;
  constructor(
    private merchantService: MerchantService,
    public dialog: MatDialog,
    private snackbarService: SnackBarService) { }

  ngOnInit(): void {
    this.merchantService.getAddMerchantMetaData()
      .subscribe(
        (res) => {
          this.data = res.response;
          this.merchantType = this.merchant.merchantType;
          this.merchantTy = this.merchantType.name;
          this.medaData = this.merchant;
          this.industryObj = this.merchant.industry;
          this.bankDetails = this.merchant.bank;
          this.paymentOption = this.merchant.paymentOptions;
          this.businessTypeDetails = this.merchant.merchantBusinessType;
          this.signatorDirectors = this.merchant.signatorDirectors;
          this.showTable = true;
          this.displayedColumnsKeys = this.displayedColumns.map((col) => col.key);
          this.dataSource = this.paymentOption.filter((user) => user);

          this.showCommissionTable = true;
          this.displayedCommissionColumnsKeys = this.displayedCommissionColumns.map((col) => col.key);
          this.dataSourceCommission = this.merchant.paymentOptionCommissions.filter((user) => user);


          this.partenrShipDetailDisplayedColumnsKeys = this.partenrShipDetailDisplayedColumns.map((col) => col.key);
          this.dataSourcePartner = this.merchant.merchantPartnerships?.filter((user) => user);
        },
        (err) => {
        }
      );

  }

  getPaymentOption(paymentOptionID: number): string {
    return this.data.paymentOptionTypeList.find(item => item.paymentOptionTypeId === paymentOptionID) ?.name;
  }

  getPreferedIpg(preferedIpgID: number): string {
    return this.data.ipgList.find(item => item.id === preferedIpgID) ?.ipgName;
  }

  getSettlementType(settlementTypeID: number): string {
    return this.data.settlementTypeList.find(item => item.merchantSettlementTypeId === settlementTypeID) ?.name;
  }

  deleteCheckDocumentList(): void { }

  viewPgListDialog(pgList: any): void {
    if (pgList.length > 0 && pgList[0].paymentGatewayId) {
      this.dialog.open(ViewTablePopupComponent, {
        data: {
          pgList,
          metaData: this.data,
          iccList: this.comcatList,
          type: 'UPDATE'
        }
      });
    } else {
      this.snackbarService.show({ message: 'No compact MIDs to Show.', action: 'info' });
    }
  }
}
