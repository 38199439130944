import { createAction, props } from '@ngrx/store';
import { LoginOtpResponse, LoginResponse, TokenVerifyResponse } from 'src/app/modules/login/login/dtos/login-response';
import { LoginCredentials } from '../../core/models/UI/LoginCredentials';
import {  ResendOtpRequest, Verify2FAOTPRequest } from 'src/app/modules/login/login/dtos/login-request';

export enum LoginActionTypes {
  REQUEST_LOGIN = '[login] request login attempt',
  SUCCESS_LOGIN_REQUEST = '[login] success login attempt',
  FAILURE_LOGIN_REQUEST = '[login] failure login attempt',
  REQUEST_OTP_VALIDATE = '[login] verify otp',
  FAILURE_OTP_VALIDATE = '[login] otp failed', 
  RESEND_OTP = '[login] resend otp',
  SUCCESS_RESEND_OTP = '[login] success resend otp',
  FAILURE_RESEND_OTP ='[login] failure resend otp',
  REQUEST_RESEND_OTP_VALIDATE ='[login] resend otp validate',
  FAILURE_RESEND_OTP_VALIDATE ='[login] failure resend otp validate',
  REQUEST_TOKEN_VERIFY = '[login] Success Request token verify',
  SUCCESS_TOKEN_VERIFY ='[login] success token verify',
  FAILURE_TOKEN_VERIFY = '[login] failure token verify',
  SUCCESS_LOGIN = '[login] success login',
  FAILURE_LOGIN = '[login] failure login',
  SET_PASSWORD_RESET_TOKEN = '[login] set password reset token',
  
}

export const RequestLoginAction = createAction(
  LoginActionTypes.REQUEST_LOGIN,
  props<{ payload: LoginCredentials }>()
);

export const SuccessLoginAttemptAction = createAction(
  LoginActionTypes.SUCCESS_LOGIN_REQUEST,
  props<{ email: string, response: LoginOtpResponse }>()
);

export const FailureLoginAttemptAction = createAction(
  LoginActionTypes.FAILURE_LOGIN_REQUEST,
  props<{ payload: any }>()
);

export const RequestOTPValidateAction = createAction(
  LoginActionTypes.REQUEST_OTP_VALIDATE,
  props<{payload: Verify2FAOTPRequest }>()
);

export const FailureOTPValidateAction = createAction(
  LoginActionTypes.FAILURE_OTP_VALIDATE,
  props<{payload: LoginResponse }>()
);

export const ResendOtpAction = createAction(
  LoginActionTypes.RESEND_OTP,
  props<{payload: ResendOtpRequest }>()
);

export const SuccessResendOtptAction = createAction(
  LoginActionTypes.SUCCESS_RESEND_OTP,
  props<{  reseponse: LoginOtpResponse }>()
);

export const FailureResendOtptAction = createAction(
  LoginActionTypes.FAILURE_RESEND_OTP,
  props<{  payload: LoginOtpResponse }>()
);

export const RequestResendOTPValidateAction = createAction(
  LoginActionTypes.REQUEST_RESEND_OTP_VALIDATE,
  props<{payload: Verify2FAOTPRequest }>()
);
export const FailureResendOTPValidateAction = createAction(
  LoginActionTypes.FAILURE_RESEND_OTP_VALIDATE,
  props<{payload: LoginResponse }>()
);
export const RequestTokenVerifyAction = createAction(
  LoginActionTypes.REQUEST_TOKEN_VERIFY,
  props<{ payload:any }>()
);

export const SuccessTokenVerifyAction = createAction(
  LoginActionTypes.SUCCESS_TOKEN_VERIFY,
  props<{payload: TokenVerifyResponse}>()
);

export const FailureTokenVerifyAction = createAction(
  LoginActionTypes.FAILURE_TOKEN_VERIFY,
  props<{payload: TokenVerifyResponse}>()
);

export const SuccessLoginAction = createAction(
  LoginActionTypes.SUCCESS_LOGIN,
  props<{ payload: LoginResponse }>()
);

export const FailureLoginAction = createAction(
  LoginActionTypes.FAILURE_LOGIN,
  props<{ payload: any }>()
);

export const SetPasswordResetTokenAction = createAction(
  LoginActionTypes.SET_PASSWORD_RESET_TOKEN,
  props<{ payload: any }>()
);


