<div class="mt-4 gap-expanded-body">
  <div class="row d-flex align-items-center">
    <span class="material-icons-outlined gap-info-icon">info</span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Merchant Type</label>
      <div class="gap-inp-view__text">{{(merchantType.name)?merchantType.name: 'N/A' }}</div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Registered Name</label>
      <div class="gap-inp-view__text">{{(medaData.registeredName)?medaData.registeredName : 'N/A' }}</div>
    </div>
  </div>
  <div class="row d-flex align-items-center" *ngIf="merchantType?.name == 'Genie'">
      <span class="material-icons gap-info-icon"></span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Secondary Acquirer</label>
        <div class="gap-inp-view__text">{{(medaData.secondaryAcquirer)?medaData.secondaryAcquirer : 'N/A' }}</div>
      </div>
      <div class="col-6 col-md-6 gap-inp-view" *ngIf="medaData.secondaryAcquirer">
        <label class="gap-inp-view__label">MID 3 Value</label>
        <div class="gap-inp-view__text">
          {{(medaData.mid3)?medaData.mid3 : 'N/A' }}
        </div>
      </div>
    </div>
  <div class="row d-flex align-items-center">
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Doing Business Name</label>
      <div class="gap-inp-view__text">{{(medaData.businessName)?medaData.businessName : 'N/A' }}</div>
    </div>
    <div class="col-6 col-md-6 gap-inp-view">
      <label class="gap-inp-view__label">Doing Business Address 1</label>
      <div class="gap-inp-view__text">
        {{(medaData.doingBusinessStreetLineOne)?medaData.doingBusinessStreetLineOne : 'N/A' }}
      </div>
    </div>
  </div>
  <div class="row d-flex align-items-center">
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Doing Business Address 2</label>
      <div class="gap-inp-view__text">
        {{(medaData.doingBusinessStreetLineTwo)?medaData.doingBusinessStreetLineTwo : 'N/A' }}</div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Doing Business Address 3</label>
      <div class="gap-inp-view__text">
        {{(medaData.doingBusinessStreetLineThree)?medaData.doingBusinessStreetLineThree : 'N/A' }}
      </div>
    </div>
  </div>
  <div class="row d-flex align-items-center">
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Genie MID</label>
      <div class="gap-inp-view__text">{{ (medaData.mid2)?medaData.mid2 : 'N/A' }}</div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view" *ngIf="merchantType?.name == 'Genie'">
      <label class="gap-inp-view__label"> Business Registration Number</label>
      <div class="gap-inp-view__text">
        {{(medaData.businessRegistrationNumber)?medaData.businessRegistrationNumber : 'N/A' }}
      </div>
    </div>
  </div>
  <div class="row d-flex align-items-center" *ngIf="merchantType?.name == 'Genie'">
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Registered Address 1 / Principle Place of Business 1</label>
      <div class="gap-inp-view__text">
        {{(medaData.streetLine1 )?medaData.streetLine1 : 'N/A' }}</div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Registered Address 2 / Principle Place of Business 2</label>
      <div class="gap-inp-view__text">
        {{(medaData.streetLine2)?medaData.streetLine2 : 'N/A' }}
      </div>
    </div>
  </div>
  <div class="row d-flex align-items-center" *ngIf="merchantType?.name == 'Genie'">
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Registered Address 3 / Principle Place of Business 3</label>
      <div class="gap-inp-view__text">
        {{ (medaData.streetLine3)?medaData.streetLine3 : 'N/A' }}</div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Date of Registration / Commencements of Business</label>
      <div class="gap-inp-view__text">{{ (medaData.registeredDate)?(medaData.registeredDate| date:'longDate' ): 'N/A' }}</div>
    </div>
  </div>
  <div class="row d-flex align-items-center" *ngIf="merchantType?.name == 'Genie'">
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Nature of Business</label>
      <div class="gap-inp-view__text">
        {{(medaData.businessNature)?medaData.businessNature : 'N/A' }}
      </div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Income Tax File No.</label>
      <div class="gap-inp-view__text">{{(medaData.taxFileNo)?medaData.taxFileNo : 'N/A' }}</div>
    </div>
  </div>
  <div class="row d-flex align-items-center" *ngIf="merchantType?.name == 'Genie'">
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Contact Person Name</label>
      <div class="gap-inp-view__text">
        {{(medaData.contactPersonNameOne)?medaData.contactPersonNameOne : 'N/A' }}
      </div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Contact Number 1</label>
      <div class="gap-inp-view__text">{{(medaData.contactPersonPhoneOne)?medaData.contactPersonPhoneOne : 'N/A' }}</div>
    </div>
  </div>
  <div class="row d-flex align-items-center" *ngIf="merchantType?.name == 'Genie'">
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Contact Number 2</label>
      <div class="gap-inp-view__text">
        {{(medaData.contactPersonPhoneTwo)?medaData.contactPersonPhoneTwo : 'N/A' }}
      </div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Contact Number 3</label>
      <div class="gap-inp-view__text">{{(medaData.contactPersonPhoneThree)?medaData.contactPersonPhoneThree : 'N/A' }}
      </div>
    </div>
  </div>
  <div class="row d-flex align-items-center" *ngIf="merchantType?.name == 'Genie'">
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Contact Email Address</label>
      <div class="gap-inp-view__text">
        {{(medaData.email)?medaData.email : 'N/A' }}
      </div>
    </div>
  </div>
  <div class="row pt-3 pb-2 gap-sub-section">
    <div class="col-12">
      <h6>Merchant Business Type Details</h6>
    </div>
  </div>
  <div class="row d-flex align-items-center">
    <span class="material-icons-outlined gap-info-icon">info</span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Type of Business</label>
      <div class="gap-inp-view__text">{{(businessTypeDetails.name)?businessTypeDetails.name : 'N/A' }}</div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view" *ngIf="businessTypeDetails?.name != 'eZCash'">
      <label class="gap-inp-view__label">Industry</label>
      <div class="gap-inp-view__text"> {{(industryObj?.name)?industryObj?.name : 'N/A' }}</div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view" *ngIf="businessTypeDetails?.name == 'eZCash'">
        <label class="gap-inp-view__label">eZCash Merchant Type</label>
        <div class="gap-inp-view__text">{{(medaData?.ezcashMerchantType)?medaData?.ezcashMerchantType : 'N/A' }}</div>
      </div>
      </div>
  <div class="row d-flex align-items-center" *ngIf="businessTypeDetails?.name == 'Partnership'">
      <span class="material-icons gap-info-icon"></span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Name of Business</label>
        <div class="gap-inp-view__text">  {{(medaData.businessName)?medaData.businessName : 'N/A' }}</div>
      </div>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Business Address 1</label>
        <div class="gap-inp-view__text">{{(medaData.mailingAddress1)?medaData.mailingAddress1 : 'N/A' }}</div>
      </div>
    </div>
  <div class="row d-flex align-items-center" >
      <span class="material-icons gap-info-icon"></span>
      <div class="col-6 col-md-4 gap-inp-view" *ngIf="businessTypeDetails?.name == 'eZCash'">
        <label class="gap-inp-view__label">eZCash Merchant Alias</label>
        <div class="gap-inp-view__text">{{(medaData.ezcashMerchantAlias)?medaData.ezcashMerchantAlias : 'N/A' }}</div>
      </div>
      <div class="col-6 col-md-4 gap-inp-view" *ngIf="businessTypeDetails?.name == 'Private Limited'">
          <label class="gap-inp-view__label">Subsidiaries and Affilliates</label>
          <div class="gap-inp-view__text">{{(medaData.subsidiariesAffiliate)?medaData.subsidiariesAffiliate : 'N/A' }}</div>
        </div>
        <div class="col-6 col-md-4 gap-inp-view" *ngIf="businessTypeDetails?.name == 'Private Limited'">
            <label class="gap-inp-view__label">Fax</label>
            <div class="gap-inp-view__text">{{(medaData.fax)?medaData.fax : 'N/A' }}</div>
          </div>

    </div>
    <div class="row d-flex align-items-center" >
        <span class="material-icons gap-info-icon"></span>
        <div class="col-6 col-md-4 gap-inp-view" *ngIf="businessTypeDetails?.name == 'Partnership'">
            <label class="gap-inp-view__label">Business Address 2</label>
            <div class="gap-inp-view__text">{{(medaData.mailingAddress2)?medaData.mailingAddress2 : 'N/A' }}</div>
          </div>
          <div class="col-6 col-md-4 gap-inp-view" *ngIf="businessTypeDetails?.name == 'Partnership' || businessTypeDetails?.name == 'Sole Proprietor'">
              <label class="gap-inp-view__label">Other Connected Business Interests</label>
              <div class="gap-inp-view__text">{{(medaData.otherConnectedBusinessInterest)?medaData.otherConnectedBusinessInterest : 'N/A' }}</div>
            </div>
      </div>
  <div class="row d-flex align-items-center" *ngIf="businessTypeDetails?.name == 'Clubs Societies, Charities, NGO, Associations'">
      <span class="material-icons gap-info-icon"></span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Source and Level of Income/Funding</label>
        <div class="gap-inp-view__text">{{(medaData.income)?medaData.income : 'N/A' }}</div>
      </div>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Other Connected Institutions/ Associations/ Organizations</label>
        <div class="gap-inp-view__text">{{(medaData.otherNgoName)?medaData.otherNgoName : 'N/A' }}</div>
      </div>
    </div>
  <div class="row d-flex align-items-center" *ngIf="businessTypeDetails?.name == 'Sole Proprietor'">
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Name of Sole Proprietor</label>
      <div class="gap-inp-view__text">{{(medaData.soleProprietorName)?medaData.soleProprietorName : 'N/A' }}</div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">NIC No. of the Sole Proprietor</label>
      <div class="gap-inp-view__text">{{(medaData.nicSoleProprieto)?medaData.nicSoleProprieto : 'N/A' }}</div>
    </div>
  </div>
    <div class="row d-flex align-items-center"  *ngFor="let signatorDirector of signatorDirectors;let i=index">
        <span class="material-icons gap-info-icon" *ngIf="businessTypeDetails?.name == 'Private Limited'"></span>
        <div class="col-6 col-md-4 gap-inp-view" *ngIf="businessTypeDetails?.name == 'Private Limited'">
          <label class="gap-inp-view__label">Signatory / Director Name {{i+1}}: </label>
          <div class="gap-inp-view__text">
            {{(signatorDirector?.signatoryDirectorName)?signatorDirector?.signatoryDirectorName : 'N/A' }}
          </div>
        </div>
        <div class="col-6 col-md-4 gap-inp-view" *ngIf="businessTypeDetails?.name == 'Private Limited'">
          <label class="gap-inp-view__label">Signatory / Director NIC {{i+1}}:</label>
          <div class="gap-inp-view__text">{{(signatorDirector?.signatoryDirectorNic)?signatorDirector?.signatoryDirectorNic : 'N/A' }}
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center" *ngIf="businessTypeDetails?.name == 'Clubs Societies, Charities, NGO, Associations'">
          <span class="material-icons gap-info-icon"></span>
          <div class="col-6 col-md-4 gap-inp-view">
            <label class="gap-inp-view__label">Name of Authorized Signatory 1: </label>
            <div class="gap-inp-view__text">
              {{(medaData.signatoryOneName)?medaData.signatoryOneName : 'N/A' }}
            </div>
          </div>
          <div class="col-6 col-md-4 gap-inp-view">
            <label class="gap-inp-view__label">NIC of Authorized Signatory 1:</label>
            <div class="gap-inp-view__text">{{(medaData.signatoryOneNic)?medaData.signatoryOneNic : 'N/A' }}
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-center" *ngIf="businessTypeDetails?.name == 'Clubs Societies, Charities, NGO, Associations'">
            <span class="material-icons gap-info-icon"></span>
            <div class="col-6 col-md-4 gap-inp-view">
              <label class="gap-inp-view__label">Name of Authorized Signatory 2:</label>
              <div class="gap-inp-view__text">
                {{(medaData.signatoryTwoName)?medaData.signatoryTwoName : 'N/A' }}
              </div>
            </div>
            <div class="col-6 col-md-4 gap-inp-view">
              <label class="gap-inp-view__label">NIC of Authorized Signatory 2:</label>
              <div class="gap-inp-view__text">{{(medaData.signatoryTwoNic)?medaData.signatoryTwoNic : 'N/A' }}
              </div>
            </div>
          </div>

  <div class="row pt-3 pb-2 gap-sub-section" *ngIf="showTable && merchantTy=='Genie'">
    <div class="col-12">
      <h6>Payment Instructions</h6>
    </div>
  </div>
  <div class="row d-flex align-items-center" *ngIf="merchantTy=='Genie'">
    <span class="material-icons-outlined gap-info-icon">info</span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Bank Name</label>
      <div class="gap-inp-view__text">{{bankDetails?.name}}</div>
    </div>
    <div class="col-6 col-md-4 gap-inp-view" *ngIf="merchantTy=='Genie'">
      <label class="gap-inp-view__label">Branch Code</label>
      <div class="gap-inp-view__text">{{medaData?.branchName}}</div>
    </div>
  </div>
  <div class="row d-flex align-items-center"*ngIf="merchantTy=='Genie'" >
    <span class="material-icons gap-info-icon"></span>
    <div class="col-6 col-md-4 gap-inp-view">
      <label class="gap-inp-view__label">Account Number</label>
      <div class="gap-inp-view__text">{{medaData?.accountNumber}}</div>
    </div>
  </div>

  <div class="row pt-3 pb-2 gap-sub-section" *ngIf="showTable && businessTypeDetails?.name == 'Partnership'">
      <div class="col-12">
        <h6>Partnership Details</h6>
      </div>
    </div>
    <section *ngIf="showTable && businessTypeDetails?.name == 'Partnership'">
      <!-- get the all Partnership Details list -->
      <div class="gap-table">
        <table mat-table [dataSource]="dataSourcePartner" matSort matSortActive="Range" matSortDirection="asc"
          matSortDisableClear>
          <ng-container *ngFor="let dispCol of partenrShipDetailDisplayedColumns; let colIndex = index" matColumnDef="{{dispCol.key}}">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="">{{dispCol.header}}</th>
                <td mat-cell *matCellDef="let element" class="mat-column-vinCode">
                    <span *ngIf="dispCol.key != 'action'">
                  {{element[dispCol.key]}}
                </span>
                </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="partenrShipDetailDisplayedColumnsKeys; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: partenrShipDetailDisplayedColumnsKeys"></tr>
        </table>
      </div>
      <!-- paginator -->
      <mat-paginator [length]="resultsLength" [pageSize]="10"></mat-paginator>
    </section>

  <div class="row pt-3 pb-2 gap-sub-section" *ngIf="showTable">
    <div class="col-12">
      <h6>Payment Terms</h6>
    </div>
  </div>
  <section *ngIf="showTable">
    <!-- get the all payment terms list -->
    <div class="gap-table">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="Range" matSortDirection="asc"
        matSortDisableClear>
        <ng-container *ngFor="let dispCol of displayedColumns; let colIndex = index" matColumnDef="{{dispCol.key}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header id="">{{dispCol.header}}</th>
          <td mat-cell *matCellDef="let element" class="mat-column-vinCode">
            <span *ngIf="element['paymentOptionTypeId'] === 1">
                <span *ngIf="dispCol.key === 'paymentOptionTypeId'">
                  {{getPaymentOption(element[dispCol.key])}}
                </span>
                <span *ngIf="dispCol.key === 'merchantPreferedIpgId'">
                  {{getPreferedIpg(element[dispCol.key])}}
                </span>
                <span *ngIf="dispCol.key === 'settlementTypeId'">
                  {{getSettlementType(element[dispCol.key])}}
                </span>
                <span
                  *ngIf="(dispCol.key !== 'action') && (dispCol.key !== 'paymentOptionTypeId') && (dispCol.key !== 'merchantPreferedIpgId') && (dispCol.key !== 'settlementTypeId')">
                  {{element[dispCol.key]}}
                </span>
                <span *ngIf="dispCol.key === 'compact'">
                  <button mat-icon-button (click)="viewPgListDialog(element['pgList'])" matTooltip="View MIDs">
                    <mat-icon>visibility</mat-icon>
                  </button>
                </span>
              </span>
              <span *ngIf="element['paymentOptionTypeId'] !== 1">
                <span *ngIf="dispCol.key === 'paymentOptionTypeId'">
                  {{getPaymentOption(element[dispCol.key])}}
                </span>
                <span *ngIf="dispCol.key === 'merchantPreferedIpgId'">
                  {{getPreferedIpg(element[dispCol.key])}}
                </span>
                <span *ngIf="dispCol.key === 'settlementTypeId'">
                  {{getSettlementType(element[dispCol.key])}}
                </span>
                <span
                  *ngIf="(dispCol.key !== 'action') && (dispCol.key !== 'paymentOptionTypeId') && (dispCol.key !== 'merchantPreferedIpgId') && (dispCol.key !== 'settlementTypeId')">
                  {{element[dispCol.key]}}
                </span>
              </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsKeys; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsKeys"></tr>
      </table>
    </div>
    <!-- paginator -->
    <mat-paginator [length]="resultsLength" [pageSize]="5"></mat-paginator>
  </section>

  <div class="row pt-3 pb-2 gap-sub-section" *ngIf="showCommissionTable">
    <div class="col-12">
      <h6>Payment Option Commissions</h6>
    </div>
  </div>
  <section *ngIf="showCommissionTable">
    <!-- get the all payment terms list -->
    <div class="gap-table">
      <table mat-table [dataSource]="dataSourceCommission" matSort matSortActive="Range" matSortDirection="asc"
        matSortDisableClear>
        <ng-container *ngFor="let dispCol of displayedCommissionColumns; let colIndex = index"
          matColumnDef="{{dispCol.key}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header id="">{{dispCol.header}}</th>
              <td mat-cell *matCellDef="let element" class="mat-column-vinCode">
                <span *ngIf="dispCol.key === 'paymentOptionType'" class="gap-capitalize">
                    {{getPaymentOption(element[dispCol.key])}}
                </span>
                <span *ngIf="(dispCol.key !== 'action') && (dispCol.key !== 'paymentOptionType')" class="gap-capitalize">
                    {{element[dispCol.key]}}
                </span>
              </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedCommissionColumnsKeys; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedCommissionColumnsKeys"></tr>
      </table>
    </div>
    <div class="row d-flex align-items-center" *ngIf="merchantTy=='Genie'">
        <span class="material-icons gap-info-icon"></span>
        <div class="col-6 col-md-4 gap-inp-view">
          <label class="gap-inp-view__label">Sale Person Name</label>
          <div class="gap-inp-view__text">{{(medaData.salePersonName)?medaData.salePersonName : 'N/A' }}</div>
        </div>
        <div class="col-6 col-md-4 gap-inp-view" *ngIf="merchantTy=='Genie'">
          <label class="gap-inp-view__label">Sale Person NIC </label>
          <div class="gap-inp-view__text">{{(medaData.salePersonId)?medaData.salePersonId : 'N/A' }}</div>
        </div>
      </div>
      <div class="row d-flex align-items-center" >
          <span class="material-icons gap-info-icon" ></span>
          <div class="col-6 col-md-4 gap-inp-view" *ngIf="merchantTy=='Genie'">
            <label class="gap-inp-view__label">Payment Channel</label>
            <div class="gap-inp-view__text" *ngFor="let paymentChannel of medaData.paymentChannels;">{{(paymentChannel.name)?paymentChannel.name : 'N/A' }}</div>
          </div>
          <div class="col-6 col-md-4 gap-inp-view" *ngIf="merchantTy=='Genie'">
              <label class="gap-inp-view__label">Redirect URL</label>
              <div class="gap-inp-view__text">{{(medaData.tknMerchantBaseUrl)?medaData.tknMerchantBaseUrl : 'N/A' }}</div>
            </div>
        </div>
        <div class="row d-flex align-items-center" >
            <span class="material-icons gap-info-icon" ></span>
            <div class="col-6 col-md-4 gap-inp-view">
              <label class="gap-inp-view__label">Enable Void</label>
              <div class="gap-inp-view__text" >  {{medaData.isVoidEnabled == true ? 'Yes':'No'}}</div>
            </div>
            <div class="col-6 col-md-4 gap-inp-view" *ngIf="medaData.isVoidEnabled == true">
              <label class="gap-inp-view__label">Void Cap</label>
              <div class="gap-inp-view__text">{{(medaData.voidCap)?medaData.voidCap : 'N/A' }}</div>
            </div>
          </div>
    <div class="row pt-3 pb-2 gap-sub-section" *ngIf="showCommissionTable && merchantTy=='Genie'">
      <div class="col-12">
        <h6>Web Checkout Channel Disable Permission </h6>
      </div>
    </div>
    <div class="row d-flex align-items-center" *ngIf="merchantTy=='Genie'">
      <span class="material-icons-outlined gap-info-icon">info</span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Sign In and Checkout</label>
        <div class="gap-inp-view__text">
            {{medaData.wcSignInAllowed == true ? 'Yes':'No'}}
        </div>
      </div>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Dynamic QR Code</label>
        <div class="gap-inp-view__text">
            {{medaData.wcQrCodeAllowed == true ? 'Yes':'No'}}
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-center" *ngIf="merchantTy=='Genie'">
      <span class="material-icons gap-info-icon"></span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Guest Checkout</label>

        <div class="gap-inp-view__text">
            {{medaData.wcGuestAllowed == true ? 'Yes':'No'}}
        </div>
      </div>
    </div>
    <div class="row pt-3 pb-2 gap-sub-section" *ngIf="showCommissionTable && merchantTy=='Genie'">
      <div class="col-12">
        <h6>Transaction Alert Details</h6>
      </div>
    </div>
    <div class="row d-flex align-items-center" *ngIf="merchantTy=='Genie'">
      <span class="material-icons-outlined gap-info-icon">info</span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Phone Number</label>
        <div class="gap-inp-view__text">{{(medaData?.transactionAlertContactNumber)?medaData?.transactionAlertContactNumber:'N/A'}}</div>
      </div>
    </div>
    <div class="row pt-3 pb-2 gap-sub-section" *ngIf="showCommissionTable && merchantTy=='Genie'">
      <div class="col-12">
        <h6>OTC Payment Confirmation Pop Up</h6>
      </div>
    </div>
    <div class="row d-flex align-items-center" *ngIf="merchantTy=='Genie'">
      <span class="material-icons-outlined gap-info-icon">info</span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Minimum Amount</label>
        <div class="gap-inp-view__text">{{(medaData?.otcPaymentPopupMinAmount)?medaData?.otcPaymentPopupMinAmount:'N/A'}}</div>
      </div>
    </div>
    <div class="row pt-3 pb-2 gap-sub-section" *ngIf="showCommissionTable && merchantTy=='Genie'">
      <div class="col-12">
        <h6>Enable Timeout</h6>
      </div>
    </div>
    <div class="row d-flex align-items-center" *ngIf="merchantTy=='Genie'">
      <span class="material-icons-outlined gap-info-icon">info</span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Time Out</label>
        <div class="gap-inp-view__text">
         {{medaData.enabledTimeOut == true ? 'Yes':'No'}}
        </div>
      </div>
    </div>
    <div class="row d-flex" *ngIf = "medaData?.logo">
      <span class="pt-4 material-icons-outlined gap-info-icon">image</span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Logo</label>
        <div class="gap-inp-view__img">
          <img [src] = "medaData?.logo" alt="logo" />
        </div>
      </div>
    </div>
    <div class="row pt-3 pb-2 gap-sub-section" *ngIf="showCommissionTable && merchantTy=='Genie'">
      <div class="col-12">
        <h6>3DS Enablement</h6>
      </div>
    </div>
    <div class="row d-flex align-items-center" *ngIf="merchantTy=='Genie'">
      <span class="material-icons-outlined gap-info-icon">info</span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Allow 3DS for Tokenized Transactions</label>
        <div class="gap-inp-view__text">
         {{(medaData?.isThreeDsTxnSupport)?medaData?.threeDsThreshold:'Not enabled'}}
        </div>
      </div>
    </div>
    <div class="row d-flex" *ngIf = "medaData?.logo">
      <span class="pt-4 material-icons-outlined gap-info-icon">image</span>
      <div class="col-6 col-md-4 gap-inp-view">
        <label class="gap-inp-view__label">Logo</label>
        <div class="gap-inp-view__img">
          <img [src] = "medaData?.logo" alt="logo" />
        </div>
      </div>
    </div>
  </section>
</div>
